import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';

import SettingsPageContext from 'contexts/SettingsPageContext';
import { ManageEmailsContext } from 'pages/manageEmails/ManageEmails';
import {
  deleteInviteScheduleById,
  getInviteScheduleById,
  getInviteSchedules,
} from 'redux/emailManagement/emailManagementActions';
import { DELETE_SCHEDULE_PREFIX } from 'utils/constants/manageEmails';
import { getAntdPaginationProps } from 'utils/helpers';
import { getEmailSchedulesColumns } from 'utils/helpers/emailsManagementHelper';

function Schedules() {
  const dispatch = useDispatch();
  const {
    inviteSchedules: { data: inviteSchedules, loading },
    deleteEmailItems: { loading: deleteInviteSchedulesLoading },
  } = useSelector((s) => s.emailManagement);

  const { params, onChangePagination } = useContext(SettingsPageContext);
  const { setIsOpenSendEmailModal, inviteEmailDataRef } =
    useContext(ManageEmailsContext);

  const onViewSchedule = (schedule) => {
    dispatch(getInviteScheduleById(schedule.id));
    setIsOpenSendEmailModal(true);
    inviteEmailDataRef.current = { inviteId: schedule.id };
  };

  const onDeleteSchedule = (schedule) => {
    dispatch(
      deleteInviteScheduleById({
        params,
        itemId: schedule.id,
        prefix: DELETE_SCHEDULE_PREFIX,
      }),
    );
  };

  useEffect(() => {
    dispatch(getInviteSchedules({ ...params }));
  }, [params]);

  return (
    <div>
      <Table
        className="left-align"
        scroll={{ x: 'max-content' }}
        columns={getEmailSchedulesColumns(
          onViewSchedule,
          onDeleteSchedule,
          deleteInviteSchedulesLoading,
        )}
        dataSource={inviteSchedules.results}
        loading={loading}
        pagination={{
          ...getAntdPaginationProps({
            data: inviteSchedules,
            currentPage: params.page ?? 1,
            onChangePagination,
          }),
        }}
      />
    </div>
  );
}

export default Schedules;
