import { createSlice } from '@reduxjs/toolkit';

import { TABLE_DATA } from 'utils/constants';

import { getUserList } from './usersActions';

const INITIAL_STATE = {
  users: TABLE_DATA,
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.users.loading = true;
    });
    builder.addCase(getUserList.rejected, (state) => {
      state.users.loading = false;
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.users.loading = false;
      state.users.data = action.payload;
    });

    return builder;
  },
});

export default usersSlice;
