import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { Header, Loader } from 'components';
import DashboardAlerts from 'components/dashboardAlerts/DashboardAlerts';
import { getUserAndInitialiseData } from 'redux/authentication/authenticationActions';
import {
  EDIT_PROFILE_URL,
  INSIGHTS_URL,
  SETTINGS_URL,
} from 'utils/constants/urlConstants';
import { useOnboardingRedirect } from 'utils/hooks';

import './_dashboardLayout.scss';

function DashboardLayoutHeader({ title, subTitle }) {
  return (
    <div className="dashboard-layout-header">
      <h2>{title}</h2>
      <p>{subTitle}</p>
    </div>
  );
}

function DashboardLayout() {
  const { isActiveOrg } = useSelector((s) => s.authentication);
  const { isLoading } = useOnboardingRedirect();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isNewDesignLayout = useMemo(() => {
    const urlsToCheck = [SETTINGS_URL, EDIT_PROFILE_URL, INSIGHTS_URL];
    if (urlsToCheck.some((url) => pathname.includes(url))) {
      return true;
    }
    return false;
  }, [pathname]);

  useEffect(() => {
    if (isActiveOrg) dispatch(getUserAndInitialiseData());
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Header />
      {isNewDesignLayout ? (
        <main className="cxmeter-main-v2">
          <div className="cxmeter-container-v2">
            <Outlet />
          </div>
        </main>
      ) : (
        <main className="cxmeter-main">
          <div className="cxmeter-container">
            <DashboardAlerts />
            <Outlet />
          </div>
        </main>
      )}
    </>
  );
}
DashboardLayout.DashboardLayoutHeader = DashboardLayoutHeader;
export default DashboardLayout;
