/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import { SettingsPageContext } from 'contexts';
import { getLocationById } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { resetLocationDetail } from 'redux/locationsAndRegions/locationsAndRegionsSlice';

import ExperienceForm from '../components/experienceForm/ExperienceForm';
import LocationInfoForm from '../components/locationInfoForm/LocationInfoForm';
import GenerateServiceRequest from './ServiceRequest';

import './_locationDetailForm.scss';
import 'pages/onboarding/regOrgaisation/_regOrganisation.scss';

export default function LocationDetailForm() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const {
    location: { data: location },
  } = useSelector((state) => state.locationsAndRegions);

  const { activeSubTab, subTabHandler } = useContext(SettingsPageContext);

  useEffect(() => {
    if (locationId && locationId !== 'new') {
      dispatch(getLocationById(locationId));
    }
    return () => {
      dispatch(resetLocationDetail());
    };
  }, [locationId]);

  useEffect(() => {
    if (location?.name)
      subTabHandler({
        isActive: true,
        heading: location.name,
        subHeading: 'Manage and link customer experience with locations',
        hideSearchFilter: true,
      });
  }, [location]);

  useEffect(() => {
    if (!activeSubTab) {
      subTabHandler({
        isActive: true,
        heading: 'Location Information',
        subHeading: 'Manage and link customer experience with locations',
        hideSearchFilter: true,
      });
    }
  }, []);

  return (
    <div className="locationDetailContainer">
      <Collapse
        defaultActiveKey={locationId === 'new' && ['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={[
          {
            key: '1',
            label: (
              <div className="locationDetailContainerHeaderContent">
                <div>
                  <h4>{location?.name || 'Location Information'}</h4>
                  <p>Location information goes here.</p>
                </div>
                <GenerateServiceRequest />
              </div>
            ),
            children: <LocationInfoForm />,
          },
        ]}
      />
      {locationId !== 'new' && <ExperienceForm />}
    </div>
  );
}
