/* eslint-disable react/no-unstable-nested-components */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select } from 'antd';
import { isNil } from 'lodash';

import { DEFAULT_PAGINATED_DROPDOWN_SIZE } from 'utils/constants';

import './_selectAndCreateOptionDropdown.scss';

const SelectAndCreateOptionDropdown = forwardRef(
  (
    {
      addOptionLabel = 'Add item',
      addOptionPlaceholder = 'Please enter item',
      disabled = false,
      disabledItems = {},
      disableVirtualScroll,
      dropdownError,
      dropdownStyle = {},
      isNewOptionLoading = false,
      isPaginationLoading = false,
      labelInValue = false,
      labelKey = 'name',
      listHeight,
      newOptionError,
      newOptionValue,
      nextDataAction = null,
      onAddNewOption,
      onChange,
      onChangeNewOption,
      onTabChange,
      options,
      pageSize = DEFAULT_PAGINATED_DROPDOWN_SIZE,
      paginationData = null,
      placeholder,
      placement,
      popupContainerRef = document.body,
      removeFocusStyle = true,
      selectedOption,
      selectTabs = [],
      showAddOption = false,
      showSearch = true,
      styles = {},
      suffixIcon,
      useParentAsPopupContainer = true,
      valueKey = 'id',
      wrapperClassName = '',
      wrapperStyles = {},
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(selectTabs[0]);
    const [open, setOpen] = useState(false);

    const activeTabRef = useRef(activeTab);
    const activeTabScrollRef = useRef(null);

    const handleNewOptionChange = useCallback(
      (value) => {
        onChangeNewOption?.(value);
      },
      [onChangeNewOption],
    );

    const handleAddItem = useCallback(
      (e) => {
        e.preventDefault();
        onAddNewOption?.();
      },
      [onAddNewOption],
    );

    const handleScrollToEnd = useCallback(
      (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight < scrollHeight - 1) return;

        const pagination = paginationData ?? activeTab?.paginationData;
        const nextAction = nextDataAction ?? activeTab?.nextDataAction;
        const paginationLoading =
          activeTab?.isPaginationLoading || isPaginationLoading;
        if (!pagination || !nextAction || paginationLoading) return;

        const { count, results } = pagination;

        if (count > results.length) {
          const nextPage = Math.ceil(results.length / pageSize) + 1;
          dispatch(nextAction({ page: nextPage, size: pageSize }));
        }
      },
      [
        dispatch,
        paginationData,
        nextDataAction,
        activeTab,
        isPaginationLoading,
      ],
    );

    const handleTabChange = (tab) => {
      if (disableVirtualScroll) {
        const optionContainer = document.querySelector(
          '.rc-virtual-list-holder',
        );
        if (!optionContainer) return;
        if (tab.id !== activeTabRef.current?.id) {
          activeTabScrollRef.current = optionContainer.scrollTop;
          optionContainer.scrollTop = 0;
        } else if (!isNil(activeTabScrollRef.current)) {
          setTimeout(() => {
            optionContainer.scrollTop = activeTabScrollRef.current;
          }, 0);
        }
      }
      onTabChange?.(tab);
      setActiveTab(tab);
    };

    const handleOptionChange = (newOption) => {
      activeTabRef.current = activeTab;
      onChange(newOption, activeTab);
    };

    const onDropdownVisibleChange = (isOpen) => {
      setOpen(isOpen);
      if (!isOpen) {
        setActiveTab(activeTabRef.current);
      }
    };

    const selectOptions = useMemo(() => {
      const currentOptions = activeTab ? activeTab.options : options;
      const optionsList = currentOptions.map(
        ({ [labelKey]: label, [valueKey]: value }) => ({
          value,
          label,
          disabled: disabledItems[value],
        }),
      );
      const paginationLoading =
        activeTab?.isPaginationLoading || isPaginationLoading;
      if (paginationLoading) {
        optionsList.push({
          value: 'loading',
          label: 'Loading...',
          disabled: true,
          className: 'pagination-loading',
        });
      }
      return optionsList;
    }, [
      options,
      disabledItems,
      labelKey,
      valueKey,
      activeTab,
      isPaginationLoading,
    ]);

    useImperativeHandle(ref, () => ({
      open,
      setOpen,
    }));

    const isOptionSelected = useMemo(() => {
      return (
        (typeof selectedOption === 'number' && !Number.isNaN(selectedOption)) ||
        (typeof selectedOption === 'string' && selectedOption.trim() !== '') || // Non-empty string
        (typeof selectedOption === 'object' && selectedOption?.value)
      );
    }, [selectedOption]);

    useEffect(() => {
      if (!selectTabs.length) return;
      let currentTab;
      if (!activeTab) {
        // eslint-disable-next-line prefer-destructuring
        currentTab = selectTabs[0];
      } else {
        currentTab = selectTabs.find((t) => t.id === activeTab.id);
      }
      setActiveTab(currentTab);
      activeTabRef.current = currentTab;
    }, [selectTabs]);

    return (
      <div
        style={wrapperStyles}
        className={`select-and-create-option-dropdown ${
          dropdownError ? 'invalid-dropdown' : ''
        } ${removeFocusStyle ? 'remove-default-focus-styles' : ''}
        ${wrapperClassName}`}
      >
        <Select
          className="antd-custom-select-dropdown"
          disabled={disabled}
          dropdownRender={(menu) => (
            <>
              {selectTabs.length ? (
                <div className="select-and-create-option-dropdown-tabs">
                  {selectTabs.map((tab) => (
                    <button
                      key={tab.id}
                      className={`select-tab ${
                        activeTab?.id === tab.id ? 'active' : ''
                      }`}
                      onClick={() => handleTabChange(tab)}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>
              ) : null}
              {menu}
              {showAddOption ? (
                <>
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <div
                    className={`add-option-container ${
                      isNewOptionLoading ? 'disabled-new-option' : ''
                    }`}
                  >
                    <div className="add-option">
                      <Input
                        disabled={isNewOptionLoading}
                        className="add-option-input"
                        placeholder={addOptionPlaceholder}
                        value={newOptionValue || ''}
                        onChange={({ target: { value } }) =>
                          handleNewOptionChange(value)
                        }
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        disabled={isNewOptionLoading}
                        type="text"
                        className="add-option-btn"
                        icon={<PlusOutlined />}
                        onClick={handleAddItem}
                      >
                        {addOptionLabel}
                      </Button>
                    </div>
                    <div>
                      {newOptionError ? (
                        <span className="control-error">{newOptionError}</span>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          dropdownStyle={dropdownStyle}
          filterOption={(input, option) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          getPopupContainer={(target) =>
            useParentAsPopupContainer ? target.parentNode : popupContainerRef
          }
          labelInValue={labelInValue}
          listHeight={listHeight}
          loading={isNewOptionLoading}
          onChange={handleOptionChange}
          onDropdownVisibleChange={onDropdownVisibleChange}
          onPopupScroll={handleScrollToEnd}
          onSearch={showSearch ? handleNewOptionChange : undefined}
          open={open}
          options={selectOptions}
          placeholder={placeholder || 'Select an option'}
          placement={placement}
          popupClassName="select-dropdown-options-container"
          showSearch={showSearch}
          style={styles}
          suffixIcon={suffixIcon !== undefined ? suffixIcon : undefined}
          value={isOptionSelected ? selectedOption : undefined}
          virtual={!disableVirtualScroll}
          {...(suffixIcon !== undefined ? { suffixIcon } : {})}
        />
        {dropdownError ? (
          <span className="control-error">{dropdownError}</span>
        ) : null}
      </div>
    );
  },
);

export default SelectAndCreateOptionDropdown;
