function FormikLabeledInput({
  formik,
  inputName,
  label,
  required = true,
  type = 'text',
  placeholder = 'Enter ...',
  onChange,
  wrapperClassName = '',
  ...props
}) {
  const { values, touched, errors } = formik;
  return (
    <label
      className={`${wrapperClassName} control ${
        touched[inputName] && errors[inputName] ? 'control-invalid' : ''
      }`}
    >
      {label ? (
        <span className="required-field">
          {label} {required ? <span>*</span> : null}
        </span>
      ) : null}
      <input
        type={type}
        name={inputName}
        placeholder={`${placeholder} ${required && !label ? '*' : ''}`}
        onChange={onChange ?? formik.handleChange}
        value={values[inputName]}
        {...props}
      />
      {touched[inputName] && (
        <span className="control-error">{errors[inputName]}</span>
      )}
    </label>
  );
}
export default FormikLabeledInput;
