import { useSelector } from 'react-redux';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';

import './_reviewsFilters.scss';

/**
 * @author
 * @function ReviewsList
 * */

function ReviewFilter({ placeholder, ...rest }) {
  const { data: reviewsTypes, isLoading } = useSelector(
    (state) => state.reviews.segments,
  );

  return (
    <SelectWithCustomSuffixIcon
      isPaginationLoading={isLoading}
      currentFilterClassName="review-list-filter"
      id="questions-list"
      name="question-types"
      options={reviewsTypes}
      placeholder={placeholder ?? 'Review'}
      {...rest}
    />
  );
}

export default ReviewFilter;
