import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  EXPERIENCE_FILTER,
  LOCATION_FILTER,
  REGION_BRANCH_FILTER,
  REGION_FILTER,
} from 'components/filters/filterTypes';
import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import {
  getDropdownExperiences,
  getDropdownLocations,
  getDropdownRegions,
} from 'redux/paginatedDropdowns/paginatedDropdownsActions';

import { ExpListFilter } from '.';

import './_branchExpFilter.scss';

/**
 * @author
 * @function BranchList
 * */

function RegionLocationExpFilter({ onChange, value, ...rest }) {
  const dispatch = useDispatch();
  const primaryFilterRef = useRef(null);
  const { data: locations, loading: locationsLoading } = useSelector(
    (state) => state.paginatedDropdowns.locations,
  );
  const { data: regions, loading: regionsLoading } = useSelector(
    (state) => state.paginatedDropdowns.regions,
  );

  const regionAndLocationsTabs = useMemo(() => {
    return [
      {
        id: REGION_FILTER,
        label: 'Regions',
        options: regions.results,
        paginationData: regions,
        isPaginationLoading: regionsLoading,
        nextDataAction: getDropdownRegions,
      },
      {
        id: LOCATION_FILTER,
        label: 'Locations',
        options: locations.results,
        paginationData: locations,
        isPaginationLoading: locationsLoading,
        nextDataAction: getDropdownLocations,
      },
    ];
  }, [regions, regionsLoading, locations, locationsLoading]);

  const currentTabRef = useRef(regionAndLocationsTabs[0]);

  const onTabChange = (newTab) => {
    currentTabRef.current = newTab;
  };

  const getRegionBranchFilterData = (experienceId) => {
    dispatch(getDropdownRegions({ page: 1, experience_id: experienceId }));
    dispatch(getDropdownLocations({ page: 1, experience_id: experienceId }));
  };

  const handleFilterChange = (newValue, activeTab) => {
    // When filter is cleared
    if (newValue === undefined && !activeTab) {
      if (primaryFilterRef.current === REGION_BRANCH_FILTER) {
        primaryFilterRef.current = null;
        onChange({ region: null, branch: null, experience: null });
        dispatch(getDropdownExperiences({ page: 1 }));
      } else {
        onChange({ ...value, region: null, branch: null });
      }
      return;
    }

    if (
      !primaryFilterRef.current ||
      primaryFilterRef.current === REGION_BRANCH_FILTER
    ) {
      primaryFilterRef.current = REGION_BRANCH_FILTER;
      if (activeTab?.id === REGION_FILTER) {
        onChange({ region: newValue, branch: null, experience: null });
        dispatch(
          getDropdownExperiences({ page: 1, region_id: newValue.value }),
        );
      } else if (activeTab?.id === LOCATION_FILTER) {
        onChange({ branch: newValue, region: null, experience: null });
        dispatch(
          getDropdownExperiences({ page: 1, entity_id: newValue.value }),
        );
      }
    } else if (activeTab?.id === REGION_FILTER) {
      onChange({ ...value, region: newValue, branch: null });
    } else if (activeTab?.id === LOCATION_FILTER) {
      onChange({ ...value, branch: newValue, region: null });
    }
  };

  const handleExperienceChange = (newExperience) => {
    if (
      !primaryFilterRef.current ||
      primaryFilterRef.current === EXPERIENCE_FILTER
    ) {
      primaryFilterRef.current =
        newExperience === undefined ? null : EXPERIENCE_FILTER;
      onChange({ branch: null, region: null, experience: newExperience });
      getRegionBranchFilterData(newExperience?.value);
    } else {
      onChange({ ...value, experience: newExperience });
    }
  };

  return (
    <div className="region-branch-exp-filter">
      <SelectWithCustomSuffixIcon
        id="region-location-list"
        name="region-location-name"
        currentFilterClassName="region-location-list-filter"
        placeholder="Region/Location"
        selectTabs={regionAndLocationsTabs}
        onTabChange={onTabChange}
        onChange={handleFilterChange}
        disableVirtualScroll
        value={
          currentTabRef.current?.id === REGION_FILTER
            ? value.region
            : value.branch
        }
        {...rest}
      />
      <ExpListFilter
        value={value.experience}
        onChange={handleExperienceChange}
        placeholder="Select Experience"
        {...rest}
      />
    </div>
  );
}

export default RegionLocationExpFilter;
