import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Popover } from 'antd';

import ServiceRequestModal from 'components/modals/ServiceRequestModal';
import { Icon } from 'components/ui';

const translationKey = 'settings.locations.serviceRequest';

function GenerateServiceRequest() {
  const { t } = useTranslation();
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);

  const content = (
    <div className="generate-service-request-content">
      <h6>{t(`${translationKey}.actionTitle`)}</h6>
      <p>{t(`${translationKey}.subTitle`)}</p>
      <button
        onClick={() => setIsOpenRequestModal(true)}
        style={{ paddingBlock: 5 }}
        className="cstm-btn secondary-cstm-btn"
      >
        {t(`${translationKey}.actionTitle`)}
      </button>
    </div>
  );

  return (
    <div
      role="presentation"
      className="generate-service-request-popover"
      onClick={(e) => e.stopPropagation()}
    >
      <Popover
        content={content}
        title={null}
        trigger="click"
        placement="top"
        on
      >
        <button
          type="button"
          className="cstm-btn tertiary-cstm-btn service-request-btn"
        >
          <img
            className="request-icon"
            src="/assets/icons/icon_request.svg"
            alt="Request"
          />
          <span>{t(`${translationKey}.title`)}</span>
          <Icon className="info-icon" type="info" />
        </button>
      </Popover>
      {isOpenRequestModal ? (
        <ServiceRequestModal onClose={() => setIsOpenRequestModal(false)} />
      ) : null}
    </div>
  );
}

export default GenerateServiceRequest;
