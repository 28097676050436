import { createSlice } from '@reduxjs/toolkit';

import { PAGINATED_RESPONSE } from 'utils/constants';

import {
  getDropdownExperiences,
  getDropdownLocations,
  getDropdownRegions,
} from './paginatedDropdownsActions';

const DROPDOWN_DATA = {
  data: PAGINATED_RESPONSE,
  loading: false,
};

const INITIAL_STATE = {
  regions: DROPDOWN_DATA,
  locations: DROPDOWN_DATA,
  experiences: DROPDOWN_DATA,
};

const updatePaginatedData =
  (dataKey) =>
  (state, { payload, meta: { arg } }) => {
    state[dataKey].loading = false;
    if (!arg?.page || arg?.page === 1 || arg?.isPageByPagePagination) {
      state[dataKey].data = payload;
    } else {
      state[dataKey].data.results = [
        ...state[dataKey].data.results,
        ...payload.results,
      ];
    }
  };

const paginatedDropdowns = createSlice({
  name: 'paginatedDropdowns',
  initialState: INITIAL_STATE,

  extraReducers: (builder) => {
    builder.addCase(getDropdownRegions.pending, (state, { meta: { arg } }) => {
      if (!arg?.page || arg?.page === 1)
        state.regions.data = { ...PAGINATED_RESPONSE };
      state.regions.loading = true;
    });
    builder.addCase(getDropdownRegions.rejected, (state) => {
      state.regions.loading = false;
    });
    builder.addCase(
      getDropdownRegions.fulfilled,
      updatePaginatedData('regions'),
    );

    builder.addCase(
      getDropdownLocations.pending,
      (state, { meta: { arg } }) => {
        if (!arg?.page || arg?.page === 1)
          state.locations.data = { ...PAGINATED_RESPONSE };
        state.locations.loading = true;
      },
    );
    builder.addCase(getDropdownLocations.rejected, (state) => {
      state.locations.loading = false;
    });
    builder.addCase(
      getDropdownLocations.fulfilled,
      updatePaginatedData('locations'),
    );

    builder.addCase(
      getDropdownExperiences.pending,
      (state, { meta: { arg } }) => {
        if (!arg?.page || arg?.page === 1)
          state.experiences.data = { ...PAGINATED_RESPONSE };
        state.experiences.loading = true;
      },
    );
    builder.addCase(getDropdownExperiences.rejected, (state) => {
      state.experiences.loading = false;
    });
    builder.addCase(
      getDropdownExperiences.fulfilled,
      updatePaginatedData('experiences'),
    );

    return builder;
  },
});

export default paginatedDropdowns;
