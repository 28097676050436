import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import PortalFeedback from 'components/portalFeedback/PortalFeedback';
import { Icon } from 'components/ui';
import { resetFilters } from 'redux/reducers/filterSlice';
import { resetPage } from 'redux/reducers/pageSlice';
import {
  CREATE_SURVEY_TEMPLATE,
  DIGEST,
  ENTITY_ADMIN,
  FINANCIAL_ADMIN,
  LUCKY_DRAW,
  ORGANIZATION,
  URLS,
} from 'utils/constants';
import { logout } from 'utils/helpers';

import Help from './components/help/Help';
import NotificationBell from './components/notificationBell/NotificationBell';
import Profile from './components/profilePopover/Profile';
import ProfilePopover from './components/profilePopover/ProfilePopover';

import './_header.scss';

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, parentEntity } = useSelector((state) => state.authentication);
  const { allowed_analytics: { analytics } = {} } = parentEntity ?? {};
  const currentPath = useRef(location.pathname);
  const [isOpen, setIsOpen] = useState(false);

  function handleLogoClick() {
    dispatch(resetFilters());
    dispatch(resetPage());
    navigate(URLS.DASHBOARD_URL);
  }

  if (currentPath.current !== location.pathname) {
    if (
      !(
        currentPath.current === URLS.DASHBOARD_URL &&
        location.pathname === URLS.DASHBOARD_FEEDBACK_NOTE_URL
      ) &&
      !(
        currentPath.current === URLS.DASHBOARD_FEEDBACK_NOTE_URL &&
        location.pathname === URLS.DASHBOARD_URL
      )
    ) {
      dispatch(resetFilters());
      dispatch(resetPage());
      setIsOpen(false);
      currentPath.current = location.pathname;
    }
  }

  const [showPortalFeedbackSurvey, setShowPortalFeedbackSurvey] =
    useState(false);
  useEffect(() => {
    if (user?.can_show_survey) {
      setTimeout(() => {
        setShowPortalFeedbackSurvey(true);
      }, 5000);
    }
  }, []);

  return (
    <header className="cxmeter-site-header">
      <div className="cxmeter-nav-container">
        <button
          className="cxmeter-nav-brand"
          type="button"
          onClick={handleLogoClick}
        >
          {parentEntity?.logo ? (
            <img src={parentEntity.logo} alt="logo" />
          ) : (
            <span className="avatar">
              {_.get(parentEntity, 'name[0]', '').toUpperCase()}
            </span>
          )}
        </button>
        <button
          className="cxmeter-nav-toggle"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon type="menu" />
        </button>
        <nav className={`cxmeter-nav ${isOpen ? 'cxmeter-nav-open' : ''} `}>
          <ul className="cxmeter-nav-menu cxmeter-nav-right-menu  expanded">
            <li data-active={currentPath.current === URLS.DASHBOARD_URL}>
              <NavLink to={URLS.DASHBOARD_URL}>
                <Icon type="dashboard" />
                <span className="link-title">{t('navigation.dashboard')}</span>
              </NavLink>
            </li>
            {user.type !== FINANCIAL_ADMIN ? (
              <>
                <li
                  data-active={currentPath.current === URLS.REVIEWS_LISTING_URL}
                >
                  <NavLink to={URLS.REVIEWS_LISTING_URL}>
                    <Icon type="reviews" />
                    <span className="link-title">
                      {t('navigation.feedbacks')}
                    </span>
                  </NavLink>
                </li>
                {analytics?.location_comparison || user?.is_superuser ? (
                  <li
                    data-active={
                      currentPath.current === URLS.COMPARE_BRANCH_URL
                    }
                  >
                    <NavLink to={URLS.COMPARE_BRANCH_URL}>
                      <Icon type="compare" />
                      <span className="link-title">
                        {t('navigation.locationComparison')}
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {analytics?.insights || user?.is_superuser ? (
                  <li data-active={currentPath.current === URLS.INSIGHTS_URL}>
                    <NavLink to={URLS.INSIGHTS_URL}>
                      <Icon type="analytics" />
                      <span className="link-title">
                        {t('navigation.insights')}
                      </span>
                    </NavLink>
                  </li>
                ) : null}
                {user?.is_superuser && (
                  <li data-active={currentPath.current === URLS.DIGEST_URL}>
                    <NavLink to={URLS.DIGEST_URL}>{DIGEST}</NavLink>
                  </li>
                )}

                {user?.is_superuser && (
                  <li data-active={currentPath.current === URLS.LUCKY_DRAW_URL}>
                    <NavLink to={URLS.LUCKY_DRAW_URL}>{LUCKY_DRAW}</NavLink>
                  </li>
                )}
                {user?.is_superuser && (
                  <li
                    data-active={
                      currentPath.current ===
                      URLS.ADMIN_CREATE_SURVEY_TEMPLATE_URL
                    }
                  >
                    <NavLink to={URLS.ADMIN_CREATE_SURVEY_TEMPLATE_URL}>
                      {CREATE_SURVEY_TEMPLATE}
                    </NavLink>
                  </li>
                )}
              </>
            ) : null}
          </ul>
          <hr className="cxmeter-nav-sm-only m-2" />
          <ul className="cxmeter-nav-menu cxmeter-nav-left-menu">
            {user.type !== FINANCIAL_ADMIN ? (
              <>
                <li>
                  <Help />
                </li>
                {user?.type !== ENTITY_ADMIN && (
                  <li>
                    <NavLink
                      to={URLS.CUSTOM_FEEDBACK_FORMS_SETTINGS_URL}
                      state={{ previousPath: location.pathname }}
                    >
                      <Icon type="settings" className="cxmeter-nav-icon" />
                      <span className="cxmeter-nav-icon-label">
                        {t('navigation.profilePopup.settings')}
                      </span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NotificationBell />
                </li>
              </>
            ) : null}
            <li className="cxmeter-nav-lg-only">
              <ProfilePopover />
            </li>
            <li className="cxmeter-nav-sm-only cxmeter-nav-mobile-profile">
              <Profile user={user} />
            </li>
            <li className="cxmeter-nav-sm-only cxmeter-nav-mobile-logout">
              <button onClick={logout}>
                <Icon type="logout" />
                <span>{t('navigation.profilePopup.logout')}</span>
              </button>
            </li>
            {user?.is_superuser && (
              <li>
                <NavLink
                  className="button button-cta"
                  to={URLS.ORGANIZATION_URL}
                >
                  {ORGANIZATION}
                  <span className="cxmeter-nav-icon-label">
                    {t('navigation.profilePopup.settings')}
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
      {showPortalFeedbackSurvey && (
        <PortalFeedback
          setShowPortalFeedbackSurvey={setShowPortalFeedbackSurvey}
        />
      )}
    </header>
  );
}

export default Header;
