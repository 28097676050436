/* eslint-disable camelcase */
import { forwardRef, useImperativeHandle, useState } from 'react';

import { isEmpty } from 'lodash';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { ReactComponent as ProcessingDots } from 'assets/icons/icon_processingDots.svg';
import { Icon } from 'components/ui';
import useClickOutside from 'utils/hooks/useClickOutside';

import './_googleMaps.scss';

export const getPlaceDetail = async (
  place,
  // Specify only those options which are neeeded as it costs according to fetched data.
  placeDetailsFields = ['address_components', 'geometry', 'name', 'place_id'],
) => {
  const parameter = {
    placeId: place.place_id,
    fields: placeDetailsFields,
  };
  return getDetails(parameter);
};

const GooglePlacesAutoComplete = forwardRef(
  (
    {
      onSelectPlace,
      allowSearchById = false,
      types = ['establishment'], // To Restrict types of results
    },
    ref,
  ) => {
    const [manualSearch, setManualSearch] = useState('');
    const [searchById, setSearchById] = useState(false);
    const [searchByIdError, setSearchByIdError] = useState(false);
    const [isOpenList, setIsOpenList] = useState(false);

    const clickOutsideRef = useClickOutside({
      onClickOutside: () => setIsOpenList(false),
    });

    const {
      value,
      suggestions: { status, data, loading },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        types,
      },
    });

    const handleSearchPlaceById = async ({ placeId } = {}) => {
      try {
        const placeDetails = await getPlaceDetail({
          place_id: placeId ?? manualSearch,
        });
        if (placeDetails.name) {
          onSelectPlace(placeDetails);
          setValue(placeDetails.name, false);
        }
      } catch (error) {
        setSearchByIdError('Please enter a valid place id.');
      }
    };

    const handleSelect = async (place) => {
      setManualSearch(place.place_id ?? '');
      setSearchByIdError('');
      clearSuggestions();
      setValue(place.description, false);
      setIsOpenList(false);
      const placeDetails = await getPlaceDetail(place);
      onSelectPlace(placeDetails);
    };

    const handleManuallSearchToggle = () => {
      setSearchById(true);
      setIsOpenList(false);
      clearSuggestions();
    };

    const handleUnselectPlace = () => {
      setManualSearch('');
      setValue('');
      onSelectPlace();
    };

    const handleManuallSearchChange = ({ target: { value: v } }) => {
      setSearchByIdError('');
      if (!v) {
        handleUnselectPlace();
        return;
      }
      setManualSearch(v);
    };

    const handleManuallSearchEnter = (e) => {
      if (e.key === 'Enter' && manualSearch) {
        handleSearchPlaceById();
      }
    };

    const handleInputChange = ({ target: { value: placeName } }) => {
      if (!placeName) {
        handleUnselectPlace();
        return;
      }
      setValue(placeName);
    };

    useImperativeHandle(ref, () => ({
      resetValues: () => {
        setManualSearch('');
        setSearchByIdError('');
        setSearchById(false);
      },
      setSelectedPlace: (placeId) => {
        handleSearchPlaceById({ placeId });
      },
      setSelectedValue: setValue,
    }));

    return (
      <div
        className="google-places-autocomplete-container"
        ref={clickOutsideRef}
      >
        <div className="google-place-search-container">
          <div className="google-places-autocomplete-input">
            <span
              className={`location-icon ${value ? 'location-selected' : ''}`}
            >
              <Icon type="location_on" />
            </span>
            <input
              onFocus={() => setIsOpenList(true)}
              value={value}
              onChange={handleInputChange}
              placeholder="Search"
            />
          </div>
          {searchById ? (
            <div>
              <div className="google-places-autocomplete-input mt-2">
                <span
                  className={`location-icon ${
                    value ? 'location-selected' : ''
                  }`}
                >
                  <Icon type="pin_drop" />
                </span>
                <input
                  value={manualSearch}
                  onChange={handleManuallSearchChange}
                  placeholder="Enter Manually"
                  onKeyDown={handleManuallSearchEnter}
                />
                <button
                  disabled={!manualSearch}
                  onClick={handleSearchPlaceById}
                  style={
                    manualSearch
                      ? { cursor: 'pointer', color: 'black' }
                      : { cursor: 'not-allowed' }
                  }
                  className="location-icon"
                >
                  <Icon type="search" />
                </button>
              </div>
              {searchByIdError && (
                <span className="control-error">{searchByIdError}</span>
              )}
            </div>
          ) : null}
        </div>
        {isOpenList ? (
          <div className="google-place-autocomplete-list">
            {loading ? (
              <span
                type="button"
                style={{ cursor: 'default' }}
                className="google-place-item"
              >
                <ProcessingDots />
                <span>Loading places</span>
              </span>
            ) : (
              status === 'OK' &&
              data.map((place) => {
                const { structured_formatting, description, place_id } = place;
                return (
                  <button
                    type="button"
                    className="google-place-item"
                    key={place_id}
                    onClick={() => handleSelect(place)}
                  >
                    <Icon className="location-icon" type="location_on" />
                    {isEmpty(structured_formatting.main_text) ? (
                      <span>{description}</span>
                    ) : (
                      <span>
                        <span className="location-main-text">
                          {structured_formatting.main_text}
                        </span>
                        {structured_formatting.secondary_text ? (
                          <span className="location-secondary_text">
                            , {structured_formatting.secondary_text}
                          </span>
                        ) : null}
                      </span>
                    )}
                  </button>
                );
              })
            )}
            {allowSearchById ? (
              <span
                type="button"
                className="google-place-item manuall-location-search"
              >
                Cant find?{' '}
                <button onClick={handleManuallSearchToggle}>
                  Enter place ID manually
                </button>
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  },
);

export default GooglePlacesAutoComplete;
