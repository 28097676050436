/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable import/prefer-default-export */
import React from 'react';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import { RESOLVED_STATUS, STATUS } from 'utils/constants';
import { getSegmentIconSrc } from 'utils/helpers/iconHelper';

const getStatusValue = (statusId) => {
  const val = STATUS.find((status) => status.id === statusId).name;
  if (statusId === RESOLVED_STATUS)
    return <div className="status res">{val}</div>;
  return <div className="status unp">{val}</div>;
};

export const reviewListingColumns = (onView, ordering) => [
  {
    title: 'Locations',
    dataIndex: 'entity',
    defaultSortOrder: ordering?.entity,
    sorter: {
      multiple: 2,
    },
    render: (text, row) => row.entity.name,
  },
  {
    title: 'Customer Experience',
    dataIndex: 'experience',
    defaultSortOrder: ordering?.experience,
    sorter: {
      multiple: 3,
    },
    render: (text, row) => row.experience.name,
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: 'Review',
    dataIndex: 'segment',
    defaultSortOrder: ordering?.segment,
    sorter: {
      multiple: 4,
    },
    render: (text, row) =>
      row.segment ? (
        <img
          alt="driver"
          height="30"
          width="30"
          className="feedback-review"
          src={getSegmentIconSrc(row.segment.name)}
        />
      ) : (
        <span>-</span>
      ),
  },
  {
    title: 'Date & Time',
    dataIndex: 'created_at',
    key: 'created_at',
    defaultSortOrder: ordering?.created_at,
    sorter: {
      multiple: 1,
    },
    render: (text) => dayjs(text).format('MM/DD/YYYY hh:mm A'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    defaultSortOrder: ordering?.status,
    sorter: {
      multiple: 5,
    },
    render: (text) => getStatusValue(text),
  },
  {
    title: '',
    dataIndex: 'action',
    render: (text, record, index) => (
      <a onClick={() => onView(index)}>
        <FontAwesomeIcon icon={faEye} />
        &nbsp;&nbsp;
        {text}
      </a>
    ),
  },
];
