import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import SettingTabs from 'components/settingTabs/SettingTabs';
import { DashboardLayout } from 'layouts';
import { SETTINGS } from 'utils/constants';
import { getSettingsMenuItems } from 'utils/helpers/settingsHelpers';
import { usePageTitle } from 'utils/hooks';

import './_setting.scss';

function Settings() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const menuItems = useMemo(() => {
    return getSettingsMenuItems(t);
  }, [t]);

  usePageTitle(SETTINGS);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, []);

  return (
    <div className="settings-container">
      <SettingTabs.MobileMenu menuItems={menuItems} />
      <DashboardLayout.DashboardLayoutHeader
        title="Settings"
        subTitle="Customize your account preferences and manage settings."
      />
      <SettingTabs>
        <SettingTabs.DesktopMenu menuItems={menuItems} />
        <SettingTabs.Content>
          <Outlet />
        </SettingTabs.Content>
      </SettingTabs>
    </div>
  );
}

export default Settings;
