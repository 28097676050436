import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { ConfigurationWidget } from 'components/dashboard';
import { Loader } from 'components/loader';
import { Switch } from 'components/switch';
import { Icon, Modal } from 'components/ui';
import { setConfigWidgets } from 'redux/dashboard/dashboardSlice';
import { configHelper, findKeyInArray } from 'utils/helpers';

function WidgetsConfigModal({
  isDirty,
  setIsDirty,
  handleSetEnabledWidgets,
  isOpen,
  isLoading,
  onClose,
  data,
  toggleQuestionSelection,
  toggleSingleSurveyQuestions,
  onSave,
  onCancel,
}) {
  const dispatch = useDispatch();
  const [showDiscountinuedWidgets, setShowDiscountinuedWidgets] =
    useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const itemIds = useMemo(() => {
    const ids = [];
    data?.forEach((survey) => {
      survey.questions.forEach((question) => {
        ids.push(question.id);
      });
    });
    return ids;
  }, [data]);

  const {
    reOrderQuestionsInDifferentObjectsOfSameSurvey,
    reOrderQuestionsInDifferentSurveys,
    reOrderQuestionsInSameSurvey,
    getSurveyIdFromQuestionId,
  } = configHelper;

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      let reOrderedData = [];
      const activeSurveyId = getSurveyIdFromQuestionId(data, active.id);
      const overSurveyId = getSurveyIdFromQuestionId(data, over.id);

      // If Question is in same object
      if (activeSurveyId === overSurveyId) {
        reOrderedData = reOrderQuestionsInSameSurvey(data, active.id, over.id);
      } else {
        const activeSurvey = findKeyInArray(data, 'survey_id', activeSurveyId);
        const overSurvey = findKeyInArray(data, 'survey_id', overSurveyId);
        // if active question belongs to over survey, add question to the survey object
        if (activeSurvey.survey_title === overSurvey.survey_title) {
          reOrderedData = reOrderQuestionsInDifferentObjectsOfSameSurvey(
            data,
            active.id,
            over.id,
          );
        } else {
          reOrderedData = reOrderQuestionsInDifferentSurveys(
            data,
            active.id,
            over.id,
          );
        }
      }
      handleSetEnabledWidgets(reOrderedData);
      dispatch(setConfigWidgets(reOrderedData));
      setIsDirty(true);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      fullHeight
      position="right"
      className="dashboard-widgets-modal"
    >
      <Modal.Header>
        <div>
          <h2 className="mb-2">Configure Widgets</h2>
          <div className="discontinued-widgets-settings">
            <p>Show discontinued widgets</p>
            <Switch
              checked={showDiscountinuedWidgets}
              onChange={({ target }) =>
                setShowDiscountinuedWidgets(target.checked)
              }
            />
          </div>
        </div>
        <button type="button" onClick={onClose}>
          <Icon type="logout" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="configWidgetList">
          {data?.map((widgetData, index) => (
            <DndContext
              id="config-context"
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              key={index}
            >
              <SortableContext
                items={itemIds}
                strategy={verticalListSortingStrategy}
              >
                <ConfigurationWidget
                  key={index}
                  data={widgetData}
                  currentSurveyIndex={index}
                  showDiscountinuedWidgets={showDiscountinuedWidgets}
                  toggleQuestionSelection={toggleQuestionSelection}
                  toggleSingleSurveyQuestions={toggleSingleSurveyQuestions}
                />
              </SortableContext>
            </DndContext>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer
        disablePrimaryAction={!isDirty}
        secondaryAction={onCancel}
        primaryAction={onSave}
        primaryLabel="Save"
      />
    </Modal>
  );
}

export default WidgetsConfigModal;
