import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Form, Input, Popover } from 'antd';

import { Icon } from 'components/ui';
import { createHelpItem } from 'redux/dashboard/dashboardActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

import './_help.scss';

export const HelpForm = forwardRef(
  ({ onFormFinish, hideTitle = false, defaultValues = {} }, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const dispatch = useDispatchWithErrors();
    const { isLoading } = useSelector((state) => state.dashboard.helpItem);

    const handleFormFinish = async () => {
      const values = form.getFieldsValue();
      if (!onFormFinish) {
        return dispatch(createHelpItem(values));
      }
      const { isError } = await dispatch(createHelpItem(values));
      if (!isError) {
        form.resetFields();
        onFormFinish();
      }
      return null;
    };

    useImperativeHandle(ref, () => ({
      formInstance: form,
      setFieldsValue: form.setFieldsValue,
      submitForm: handleFormFinish,
    }));

    return (
      <div className="helpContainer">
        <Form
          initialValues={defaultValues}
          form={form}
          onFinish={handleFormFinish}
        >
          {hideTitle ? null : (
            <p className="title">{t('navigation.helpPopup.title')}</p>
          )}
          <Form.Item
            name="subject"
            style={{ marginBottom: '1rem' }}
            rules={[
              { required: true, message: 'This field is required' },
              { max: 256, message: 'Subject cannot exceed 256 characters' },
            ]}
          >
            <Input placeholder="Subject *" />
          </Form.Item>
          <Form.Item
            className="request-description"
            name="description"
            style={{ marginBottom: '1rem' }}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea
              className="request-description-textarea"
              placeholder="Please write your message here *"
            />
          </Form.Item>
          {onFormFinish ? (
            <div className="buttonContainer">
              <button
                type="submit"
                className="button button-primary button-filled"
                aria-busy={isLoading ? true : null}
              >
                {t('navigation.helpPopup.submit')}
              </button>
            </div>
          ) : null}
        </Form>
      </div>
    );
  },
);

export default function Help() {
  const { t } = useTranslation();
  const helpFormRef = useRef();
  const [open, setOpen] = useState(false);

  const afterOpenChange = (isOpen) => {
    const { formInstance } = helpFormRef.current ?? {};
    if (!isOpen && formInstance?.resetFields) {
      formInstance.resetFields();
    }
  };

  const handleFormFinish = () => {
    setOpen(false);
  };

  return (
    <Popover
      afterOpenChange={afterOpenChange}
      content={<HelpForm ref={helpFormRef} onFormFinish={handleFormFinish} />}
      trigger="click"
      onOpenChange={setOpen}
      open={open}
    >
      <button type="button" className="tab-container">
        <span className="cxmeter-nav-icon-label">
          {t('navigation.profilePopup.help')}
        </span>
        <Icon className="cxmeter-nav-icon" type="help" />
      </button>
    </Popover>
  );
}
