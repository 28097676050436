import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { COUNTRY_LOOKUP_API } from 'apis/urls';
import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { getCountries } from 'redux/demographics/demographicsActions';

import { EXTREME_IP_LOOKUP_API_KEY } from '../../../config';

export default function CountryDropDown({
  onChange,
  value,
  error,
  placeholder,
  ...rest
}) {
  const dispatch = useDispatch();
  const { data: countries, loading } = useSelector(
    (state) => state.demographics.countries,
  );

  useEffect(() => {
    if (!countries || countries.length === 0) dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (countries?.length && !value) {
      fetch(`${COUNTRY_LOOKUP_API}?key=${EXTREME_IP_LOOKUP_API_KEY}`).then(
        (res) =>
          res.json().then((location) => {
            if (location?.country) {
              const foundCountry = countries.filter(
                (country) => country.name === location.country,
              )[0];
              onChange(foundCountry?.id);
            }
          }),
      );
    }
  }, [countries]);

  return (
    <SelectAndCreateOptionDropdown
      options={countries}
      isPaginationLoading={loading}
      selectedOption={value}
      dropdownError={error}
      onChange={onChange}
      placeholder={placeholder ?? 'Select Country'}
      {...rest}
    />
  );
}
