import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { getStates } from 'redux/demographics/demographicsActions';

export default function StateDropDown({
  onChange,
  value,
  countryId,
  error,
  placeholder,
  ...rest
}) {
  const dispatch = useDispatch();
  const {
    data: states,
    loading,
    countryId: stateCountryId, // To avoid redundant API call for States
  } = useSelector((state) => state.demographics.states);

  useEffect(() => {
    if (!countryId) return;
    if (countryId !== stateCountryId || isEmpty(states)) {
      dispatch(getStates({ countryId }));
    }
  }, [countryId, states.length]);

  return (
    <SelectAndCreateOptionDropdown
      options={states}
      isPaginationLoading={loading}
      selectedOption={value || undefined}
      dropdownError={error}
      onChange={onChange}
      placeholder={placeholder ?? 'Select State'}
      disabled={!countryId}
      {...rest}
    />
  );
}
