import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Table } from 'antd';
import _ from 'lodash';
import qs from 'qs';

import { Chips, Filters } from 'components';
import {
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  REVIEW_FILTER,
  STATUS_FILTER,
} from 'components/filters/filterTypes';
import ReviewPanel from 'components/reviewsListing/ReviewPanel';
import { getReviews } from 'redux/reviewsListing/reviewsListingActions';
import { REVIEWS_LISTING } from 'utils/constants';
import { OPTION_TYPE_MAPPING } from 'utils/constants/questionTypes';
import { getFilters } from 'utils/helpers';
import { usePageTitle } from 'utils/hooks';
import useOrdering from 'utils/hooks/useOrdering';

import { reviewListingColumns } from './reviewsListingColumns';

import './_reviewsListing.scss';

const defaultFilters = {
  [STATUS_FILTER]: null,
  [REVIEW_FILTER]: null,
  [BRANCH_FILTER]: {},
  [EXPERIENCE_FILTER]: {},
  [DATE_RANGE_FILTER]: {},
};

const filtersFromQueryParams = (val) => {
  const res = { ...defaultFilters };
  if (val.last_viewed_id) {
    res.last_viewed_id = val.last_viewed_id;
  }
  if (val.sort_order) {
    res.sort_order = val.sort_order;
  }
  if (_.get(val, 'entity')) {
    res[BRANCH_FILTER] = {
      value: _.get(val, 'entity'),
      label: _.get(val, 'entity_name'),
    };
  }
  if (_.get(val, 'experience')) {
    res[EXPERIENCE_FILTER] = {
      value: _.get(val, 'experience'),
      label: _.get(val, 'experience_name'),
    };
  }
  if (_.get(val, 'segment')) {
    res[REVIEW_FILTER] = {
      value: _.get(val, 'segment'),
      label: _.get(val, 'segment_name'),
    };
  }
  if (_.get(val, 'status')) {
    res[STATUS_FILTER] = {
      value: _.get(val, 'status'),
      label: _.get(val, 'status_name'),
    };
  }
  if (_.get(val, 'start_date') && _.get(val, 'end_date')) {
    res[DATE_RANGE_FILTER] = {
      startDate: _.get(val, 'start_date'),
      endDate: _.get(val, 'end_date'),
      name: _.get(val, 'range_label'),
    };
  }

  return res;
};

function ReviewsListing() {
  const [searchParams] = useSearchParams();
  const [filterStore, setFilterStore] = useState(() => {
    const params = Object.fromEntries(searchParams);
    return filtersFromQueryParams(params);
  });
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(null);
  const [showReviewPanelNav, setShowReviewPanelNav] = useState(true);
  const [pageControl, setPageControl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, count } = useSelector(
    (state) => state.reviews.reviewsList,
  );
  const [ordering, updateSorting, getOrderingForTable] = useOrdering([]);

  usePageTitle(REVIEWS_LISTING);

  const onView = (index) => {
    setCurrentReview(data[index].id);
    setCurrentReviewIndex(index);
    setShowReviewPanelNav(true);
    setShowDetailPanel(true);
  };

  const onFilterChange = (val) => {
    navigate(
      {
        search: qs.stringify({ ...getFilters(val), last_viewed_id: null }),
      },
      { replace: true },
    );
  };

  const parseExtras = (pl) => {
    let res = {};
    if (pl.type) {
      res = {
        [OPTION_TYPE_MAPPING[pl.type]]: pl.option_id,
        ..._.omit(pl, [
          'entity',
          'experience',
          'segment',
          'status',
          'start_date',
          'end_date',
          'option_id',
          'type',
        ]),
      };
    }
    return res;
  };

  const renameDateKeys = (filtersObj) => {
    if (_.get(filtersObj, 'start_date') && _.get(filtersObj, 'end_date')) {
      filtersObj['date_range[0]'] = _.get(filtersObj, 'start_date');
      filtersObj['date_range[1]'] = _.get(filtersObj, 'end_date');
    }
    return _.omit(filtersObj, ['start_date', 'end_date']);
  };

  const onSortChange = (pagination, filters, sorter) => {
    updateSorting(sorter);
  };

  useEffect(() => {
    const params = Object.fromEntries(searchParams);
    setPageControl(null);
    setFilterStore({ ...filterStore, ...filtersFromQueryParams(params) });
  }, [searchParams]);

  useEffect(() => {
    const allFilters = {
      ...parseExtras(Object.fromEntries(searchParams)),
      ...renameDateKeys(getFilters(filterStore)),
    };
    if (!Object.fromEntries(searchParams).last_viewed_id) {
      delete allFilters.last_viewed_id;
    }
    dispatch(
      getReviews({
        ordering: `${ordering.join(',')}`,
        ...pageControl,
        ...allFilters,
      }),
    );
  }, [filterStore, ordering, pageControl]);

  useEffect(() => {
    if (showDetailPanel) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [showDetailPanel]);

  useEffect(() => {
    const openFeedbackId = searchParams.get('openFeedback');
    if (openFeedbackId) {
      setCurrentReview(openFeedbackId);
      setShowReviewPanelNav(false);
      setShowDetailPanel(true);
    }
  }, [searchParams]);

  return (
    <main>
      <section className="header">
        <div>
          <h1>Feedbacks</h1>
        </div>
      </section>

      <section className="filtersWrapper">
        <p> Filters</p>
        <Filters
          value={filterStore}
          doFetchFiltersData
          onChange={onFilterChange}
        />
      </section>
      <section className="chipsWrapper">
        <Chips filters={filterStore} onDelete={onFilterChange} />
      </section>

      <section className="tableWrapper">
        <Table
          loading={isLoading && { indicator: <Spinner /> }}
          columns={reviewListingColumns(onView, getOrderingForTable(ordering))}
          scroll={{ x: 'max-content' }}
          dataSource={data}
          onChange={onSortChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => onView(rowIndex),
            };
          }}
          pagination={{
            current: pageControl?.page ?? 1,
            total: count,
            defaultPageSize: 10,
            onChange: (page, pageSize) =>
              setPageControl({ page, size: pageSize }),
            showSizeChanger: false,
          }}
          className="feedback-table"
        />

        <ReviewPanel
          isOpen={showDetailPanel}
          setIsOpen={setShowDetailPanel}
          reviewId={currentReview}
          reviewIndex={currentReviewIndex}
          totalReviews={data.length}
          handleView={onView}
          showNav={showReviewPanelNav}
        />
      </section>
    </main>
  );
}

export default ReviewsListing;
