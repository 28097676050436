/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import { getLatLng } from 'use-places-autocomplete';

import { Map } from 'components';
import GooglePlacesAutoComplete from 'components/googleMap/GooglePlacesAutoComplete';
import { Modal, Spinner } from 'components/ui';
import WarningModal from 'components/ui/modal/WarningModal';
import { getCities, getStates } from 'redux/demographics/demographicsActions';
import { getGoogleMapsReviewsCount } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { DEFAULT_MAP_BOX_COORD } from 'utils/constants';
import { getPlaceAddressComponents } from 'utils/helpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

const translationKey = 'settings.locations.googleReviews.updateAddressWarning';

function GoogleLocationModal({ isOpen, onClose, formik }) {
  const { t } = useTranslation();
  const autoCompleteRef = useRef();
  const dispatch = useDispatchWithErrors();
  const {
    countries: { data: countries },
    states: { data: states },
    cities: { data: cities },
  } = useSelector((state) => state.demographics);

  const { values } = formik;
  const { country, state, city, zip_code, google_place_id } = values;
  const placeDetailsRef = useRef(null);

  const [showWarningModal, setShowWarningModal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocationInfo, setSelectedLocationInfo] = useState();

  const onCloseModal = ({ placeId }) => {
    if (isLoading) return;
    if (autoCompleteRef.current) {
      const newPlaceId = placeId ?? google_place_id;
      if (newPlaceId && newPlaceId !== selectedLocationInfo?.placeId) {
        autoCompleteRef.current.setSelectedPlace(newPlaceId);
      }
      autoCompleteRef.current.resetValues();
    }
    onClose();
  };

  const handleLocationSelect = async (place) => {
    if (!place) {
      setSelectedLocationInfo(null);
      return;
    }
    const { lat, lng } = getLatLng(place);
    placeDetailsRef.current = { ...place, coord: [lng, lat] };
    setSelectedLocationInfo({
      coord: [lng, lat],
      name: place.name,
      placeId: place.place_id,
    });
  };

  const getLocationDemographics = async () => {
    let countryId;
    let stateId;
    let cityId;

    const {
      country: countryName,
      state: stateName,
      city: cityName,
      zip_code: zipCode,
    } = getPlaceAddressComponents(placeDetailsRef.current);

    const selectedCountry = countries.find((c) => c.name === countryName);
    if (selectedCountry) {
      let statesList = states;
      countryId = selectedCountry.id;
      if (countryId !== country) {
        statesList = (await dispatch(getStates({ countryId }))).data;
      }
      const selectedState = statesList.find((s) => s.name === stateName);
      if (selectedState) {
        let citiesList = cities;
        stateId = selectedState.id;
        if (stateId !== state) {
          citiesList = (await dispatch(getCities({ stateId }))).data;
        }
        const selectedCity = citiesList.find((c) => c.name === cityName);
        if (selectedCity) {
          cityId = selectedCity.id;
        }
      }
    }
    return {
      country: countryId,
      state: stateId,
      city: cityId,
      zip_code: zipCode ?? '',
    };
  };

  const handleSetupGoogleReviews = async (updateAddress) => {
    setShowWarningModal(false);
    setIsLoading(true);
    if (updateAddress && placeDetailsRef.current) {
      const placeDempgraphics = await getLocationDemographics();
      formik.setValues({
        ...formik.values,
        ...placeDempgraphics,
      });
    }
    const placeId = placeDetailsRef.current?.place_id;
    const { isError } = await dispatch(
      getGoogleMapsReviewsCount({
        google_place_id: placeId,
      }),
    );
    if (!isError) {
      formik.setFieldValue('google_place_id', placeId);
      onCloseModal({ placeId });
    }
    setIsLoading(false);
  };

  const handleSaveChanges = () => {
    if (
      google_place_id !== selectedLocationInfo?.placeId &&
      (country || state || city || zip_code)
    ) {
      setShowWarningModal(true);
    } else {
      handleSetupGoogleReviews(false);
    }
  };

  useEffect(() => {
    if (autoCompleteRef.current) {
      if (
        google_place_id &&
        google_place_id !== selectedLocationInfo?.placeId
      ) {
        autoCompleteRef.current.setSelectedPlace(google_place_id);
      } else if (!google_place_id && selectedLocationInfo?.placeId) {
        setSelectedLocationInfo(null);
        autoCompleteRef.current.setSelectedValue('');
      }
    }
  }, [google_place_id]);

  const showSpinner = google_place_id && !placeDetailsRef.current?.coord;

  return (
    <Modal
      className="google-location-modal"
      onClose={onCloseModal}
      open={isOpen}
      isCurveredBorderedModal
      backDropClose={false}
    >
      <Modal.Header title="Configure Google Reviews" onClose={onCloseModal} />
      <Modal.Body showSpinner={showSpinner}>
        <div>
          {showSpinner ? <Spinner /> : null}
          <p>Search address</p>
          <GooglePlacesAutoComplete
            onSelectPlace={handleLocationSelect}
            allowSearchById
            ref={autoCompleteRef}
          />
        </div>
        <Map
          pin={selectedLocationInfo?.coord ?? DEFAULT_MAP_BOX_COORD}
          zoom={10}
          showMarker={!isEmpty(selectedLocationInfo?.coord)}
          mapStyles={{ height: 300 }}
        />
      </Modal.Body>
      <Modal.Footer
        isCurveredBorderedModal
        primaryAction={handleSaveChanges}
        disablePrimaryAction={
          !selectedLocationInfo?.coord ||
          isLoading ||
          google_place_id === selectedLocationInfo?.placeId
        }
        isLoading={isLoading}
        primaryLabel="Set Up Google Reviews"
      />
      {showWarningModal ? (
        <WarningModal
          className="update-location-address-warning-modal"
          title={t(`${translationKey}.title`)}
          subTitle={t(`${translationKey}.subTitle`)}
          primaryActionTitle={t(`${translationKey}.updateAddress`)}
          secondaryActionTitle={t(`${translationKey}.retainAddress`)}
          primaryAction={() => handleSetupGoogleReviews(true)}
          secondaryAction={() => handleSetupGoogleReviews(false)}
        />
      ) : null}
    </Modal>
  );
}

export default GoogleLocationModal;
