import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import WarningModal from 'components/ui/modal/WarningModal';
import { fetchGoogleMapsReviews } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

const translationKey = 'settings.locations.googleReviewsPrice';

function GoogleReviewsPriceModal({ onClose, formik }) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const { isSubmitting, status, setStatus } = formik;

  const handleCloseModal = () => {
    setStatus(null);
    onClose();
  };

  const handleFetchGoogleReviews = (isFutureOnly) => {
    dispatch(
      fetchGoogleMapsReviews({
        is_future_only: isFutureOnly,
        entity_id: formik.values.id,
      }),
    );
    handleCloseModal();
  };

  useEffect(() => {
    if (!isSubmitting && !status?.message) {
      onClose();
    }
  }, [status, isSubmitting]);

  const title = isSubmitting
    ? t(`${translationKey}.processing.title`)
    : status?.success
    ? t(`${translationKey}.retrive.title`)
    : t(`${translationKey}.retrive.errorTitle`);

  const subTitle = isSubmitting
    ? t(`${translationKey}.processing.subTitle`)
    : status?.message;

  return (
    <WarningModal
      wrapperStyles={{ zIndex: 1250 }}
      bodyStyles={{ width: 550 }}
      title={title}
      subTitle={subTitle}
      className="google-reviews-price--warning-modal"
      {...(isSubmitting
        ? {}
        : !status?.success
        ? {
            primaryAction: handleCloseModal,
            primaryActionTitle: t(`${translationKey}.retrive.close`),
          }
        : !status?.message
        ? {}
        : {
            primaryActionTitle: t(`${translationKey}.retrive.futureOnly`),
            primaryAction: () => handleFetchGoogleReviews(true),
            secondaryActionTitle: t(`${translationKey}.retrive.fetchAll`),
            secondaryAction: () => handleFetchGoogleReviews(false),
          })}
    />
  );
}

export default GoogleReviewsPriceModal;
