import { useEffect, useState } from 'react';

import { Icon, Spinner } from 'components/ui';

import './modal.scss';

function ModalHeader({
  inputName,
  isEditable,
  formik,
  title,
  onClose,
  hideCloseIcon = false,
  icon = 'close',
  children,
}) {
  return (
    <header className="cxmeter-modal-header">
      {children ?? (
        <>
          {isEditable ? (
            <div className="edit-invite-group-name">
              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values[inputName]}
              />
              {formik.touched[inputName] && (
                <span style={{ display: 'block' }} className="control-error">
                  {formik.errors[inputName]}
                </span>
              )}
            </div>
          ) : (
            <h2>{title}</h2>
          )}
          {onClose && !hideCloseIcon ? (
            <button
              type="button"
              onClick={onClose}
              className="cxmeter-modal-close"
            >
              <Icon type={icon} />
            </button>
          ) : null}
        </>
      )}
    </header>
  );
}

function ModalFooter({
  primaryAction,
  primaryActionIcon = null,
  primaryLabel = 'Ok',
  disablePrimaryAction = false,
  secondaryAction,
  secondaryActionIcon = null,
  secondaryLabel = 'Cancel',
  disableSecondaryAction = false,
  tertiaryAction,
  tertiaryActionIcon = null,
  tertiaryLabel,
  disableTertiaryAction = false,
  tertiaryLoading = false,
  isLoading = false,
  isCurveredBorderedModal = false,
  children,
}) {
  return (
    <footer className="cxmeter-modal-footer">
      {children ?? (
        <>
          {tertiaryAction && tertiaryLabel ? (
            <span className="tertiary-action">
              <button
                aria-busy={tertiaryLoading ? true : null}
                onClick={tertiaryAction}
                disabled={disableTertiaryAction}
                className={
                  isCurveredBorderedModal
                    ? 'cstm-btn secondary-cstm-btn'
                    : 'button button-secondary'
                }
                type="button"
              >
                {!tertiaryLoading && tertiaryActionIcon}
                <span>{tertiaryLabel}</span>
              </button>
            </span>
          ) : null}
          <span className="primary-and-secondary-actions">
            {secondaryAction ? (
              <button
                onClick={secondaryAction}
                className={
                  isCurveredBorderedModal
                    ? 'cstm-btn secondary-cstm-btn'
                    : 'button button-secondary'
                }
                type="button"
                disabled={disableSecondaryAction}
              >
                {secondaryActionIcon}
                <span>{secondaryLabel}</span>
              </button>
            ) : null}
            <button
              aria-busy={isLoading ? true : null}
              onClick={primaryAction}
              disabled={disablePrimaryAction}
              className={
                isCurveredBorderedModal
                  ? 'cstm-btn primary-cstm-btn'
                  : 'button button-primary'
              }
              type="button"
            >
              {!isLoading && primaryActionIcon}
              <span>{primaryLabel}</span>
            </button>
          </span>
        </>
      )}
    </footer>
  );
}

function ModalBody({ children, showSpinner, className = '' }) {
  return (
    <article
      className={`cxmeter-modal-body-content ${className}`}
      style={
        showSpinner ? { opacity: 0.5, position: 'relative' } : { opacity: 1 }
      }
    >
      {children}
    </article>
  );
}

export default function Modal({
  children,
  open,
  onClose,
  position = 'center',
  fullHeight = false,
  className = '',
  backDropClose = true,
  isLoading = false,
  isCurveredBorderedModal = false,
  modalRef = null,
}) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (open !== isOpen) setIsOpen(open);
  }, [open]);

  const backdropClickHandler = () => {
    if (backDropClose && onClose) {
      onClose();
    }
  };

  return (
    <article
      ref={modalRef}
      className={`cxmeter-modal ${
        isCurveredBorderedModal ? 'curved-and-bordered-modal' : ''
      } ${isOpen ? 'cxmeter-modal-open' : ''} ${
        position !== 'center' ? `cxmeter-modal-${position}` : ''
      } ${fullHeight ? 'cxmeter-modal-full' : ''} ${className}`}
    >
      <div
        className="cxmeter-modal-backdrop"
        role="presentation"
        onClick={backdropClickHandler}
      />{' '}
      <div className="cxmeter-modal-body">
        {isLoading ? <Spinner /> : null}
        {children}
      </div>
    </article>
  );
}

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Body = ModalBody;
