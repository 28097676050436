import { Spin } from 'antd/lib';

import './_Spinner.scss';

function Spinner({
  children,
  isTransparentBackground = false,
  wrapperStyles = {},
}) {
  return (
    <div
      className="custom-spinner"
      style={{
        backgroundColor: isTransparentBackground ? 'transparent' : '#00000017',
        ...wrapperStyles,
      }}
    >
      {children ?? <Spin />}
    </div>
  );
}

export default Spinner;
