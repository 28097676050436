import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Pagination } from 'antd';

import { SurveyThumbnail } from 'components';
import { Spinner } from 'components/ui';
import { SettingsPageContext } from 'contexts';
import {
  deleteEntitySurvey,
  duplicateSurvey,
} from 'redux/surveys/surveysActions';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';
import {
  CUSTOM_FEEDBACK_FORMS_SETTINGS_URL,
  TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL,
} from 'utils/constants/urlConstants';
import { getAntdPaginationProps } from 'utils/helpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

function SurveyFormsListing({ isTemplateSurvey, action }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const didMount = useRef(null);
  const dispatch = useDispatchWithErrors();
  const {
    [isTemplateSurvey ? 'templateSurveyForms' : 'customSurveyForms']: {
      data: surveyForms,
      loading,
    },
  } = useSelector((s) => s.surveys);

  const [isActionsLoading, setIsActionsLoading] = useState(false);

  const { params, setParams, onChangePagination } =
    useContext(SettingsPageContext);

  const handleDeleteSurvey = async (survey) => {
    setIsActionsLoading(true);
    const { isError } = await dispatch(deleteEntitySurvey(survey.survey_id));
    if (!isError) {
      toast.success('Survey deleted successfully');
    }
    await dispatch(action({ ...params, size: 20 }));
    setIsActionsLoading(false);
  };

  const handleDuplicateSurvey = async (survey) => {
    setIsActionsLoading(true);
    const { isError, data } = await dispatch(duplicateSurvey(survey.survey_id));
    if (!isError && data) {
      // eslint-disable-next-line no-underscore-dangle
      navigate(`${CUSTOM_FEEDBACK_FORMS_SETTINGS_URL}/${data?._id.$oid}`);
      setParams({ ...PAGINATION_AND_SORTING_PARAMS });
    }
    setIsActionsLoading(false);
  };

  const handleEditSurvey = (survey) => {
    const url = isTemplateSurvey
      ? TEMPLATES_FEEDBACK_FORMS_SETTINGS_URL
      : CUSTOM_FEEDBACK_FORMS_SETTINGS_URL;
    navigate(`${url}/${survey.survey_id}`);
  };

  useEffect(() => {
    didMount.current = false;
  }, [isTemplateSurvey]);

  useEffect(() => {
    dispatch(action({ ...params, size: 20 })).then(() => {
      if (!didMount.current) didMount.current = true;
    });
  }, [params]);

  const noResultFound = didMount.current && surveyForms.results.length < 1;
  const isLoading = isActionsLoading || loading;

  return id ? (
    <Outlet />
  ) : (
    <div
      className={`survey-forms-listing-container ${
        noResultFound ? 'no-result-found-container' : ''
      }`}
      style={{
        opacity: isLoading ? 0.5 : 1,
      }}
    >
      {isLoading ? <Spinner isTransparentBackground /> : null}
      {noResultFound ? (
        <div className="no-result-found">
          <img src="/assets/icons/icon_noResultFound.svg" alt="No Data" />
          <h3>No Result Found!</h3>
        </div>
      ) : (
        <>
          <div className="survey-forms-listing">
            <div>
              {surveyForms.results.map((survey) => (
                <SurveyThumbnail
                  key={survey.survey_id}
                  icon={survey.experience_icon}
                  onEdit={() => handleEditSurvey(survey)}
                  data={survey}
                  {...(isTemplateSurvey
                    ? {}
                    : {
                        onDelete: () => handleDeleteSurvey(survey),
                        onCopy: () => handleDuplicateSurvey(survey),
                      })}
                />
              ))}
            </div>
          </div>
          {surveyForms.results.length ? (
            <Pagination
              style={{ alignSelf: 'flex-end', color: 'black' }}
              {...getAntdPaginationProps({
                data: surveyForms,
                currentPage: params.page ?? 1,
                onChangePagination,
                defaultPageSize: 20,
              })}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default SurveyFormsListing;
