import React from 'react';
import { useSelector } from 'react-redux';

import { LocationThumbnail } from 'components/locationThumbnail';

import './_locationSelector.scss';

export default function LocationSelector({ onChange, value }) {
  const { branchList } = useSelector((state) => state.filters);

  return (
    <div className="location-selector__container">
      {branchList.map((branch) => (
        <LocationThumbnail
          selectOnMount
          selected={value?.id === branch.id}
          key={branch.id}
          onSelect={() => onChange(branch)}
        >
          {branch.name}
        </LocationThumbnail>
      ))}
    </div>
  );
}
