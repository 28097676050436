import React, { useState } from 'react';

import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Popconfirm, Popover } from 'antd';

import '../_qrSetup.scss';

export default function TriDotMenu({
  onEdit,
  onDelete,
  onDuplicate,
  onViewSurvey,
}) {
  const [open, setOpen] = useState();

  const handleSelectItem = (onClickAction) => {
    setOpen(false);
    onClickAction();
  };

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      content={
        <List bordered>
          <List.Item onClick={() => handleSelectItem(onEdit)}>Modify</List.Item>
          <List.Item onClick={() => handleSelectItem(onViewSurvey)}>
            View Survey
          </List.Item>
          <List.Item onClick={() => handleSelectItem(onDuplicate)}>
            Duplicate QR Design
          </List.Item>
          <Popconfirm
            title="Are You Sure"
            description="Do you want to delete this record"
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
            onConfirm={() => {
              handleSelectItem(onDelete);
            }}
          >
            <List.Item>Remove</List.Item>
          </Popconfirm>
        </List>
      }
      trigger="click"
      overlayInnerStyle={{ padding: 0 }}
      overlayClassName="dotMenu"
    >
      <button className="dotMenu" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
      </button>
    </Popover>
  );
}
