import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { getCities } from 'redux/demographics/demographicsActions';

export default function CityDropDown({
  onChange,
  value,
  stateId,
  error,
  placeholder,
  ...rest
}) {
  const dispatch = useDispatch();
  const {
    data: cities,
    loading,
    stateId: cityStateId, // To avoid redundant API call for Cities
  } = useSelector((state) => state.demographics.cities);

  useEffect(() => {
    if (!stateId) return;
    if (stateId !== cityStateId || isEmpty(cities)) {
      dispatch(getCities({ stateId }));
    }
  }, [stateId, cities.length]);

  return (
    <SelectAndCreateOptionDropdown
      options={cities}
      isPaginationLoading={loading}
      selectedOption={value || undefined}
      dropdownError={error}
      onChange={onChange}
      placeholder={placeholder ?? 'Select City'}
      disabled={!stateId}
      {...rest}
    />
  );
}
