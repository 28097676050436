import React, { useRef } from 'react';

import { faCaretDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isNumberOrParsableString } from 'utils/helpers';
import useClickOutside from 'utils/hooks/useClickOutside';

import SelectAndCreateOptionDropdown from './SelectAndCreateOptionDropdown';

function CustomIcon({
  isRemoveable,
  onRemove,
  onChange,
  onClear,
  isClearable,
  value,
  onIconClick,
}) {
  const shouldClearValue = isClearable && isNumberOrParsableString(value);
  const showCrossIcon = shouldClearValue || isRemoveable;

  const onClearOrRemove = (e) => {
    e.stopPropagation();
    if (showCrossIcon) {
      if (shouldClearValue) {
        onChange(undefined);
        if (onClear) onClear();
      } else if (showCrossIcon) {
        onRemove?.();
      }
    } else {
      onIconClick();
    }
  };

  return (
    <div
      role="presentation"
      className="select-dropdown-custom-icon"
      onClick={onClearOrRemove}
    >
      <FontAwesomeIcon icon={showCrossIcon ? faXmark : faCaretDown} />
    </div>
  );
}

function SelectWithCustomSuffixIcon({
  id,
  value: selectedOption,
  isClearable = true,
  isRemoveable,
  placeholder,
  onChange,
  options,
  onRemove,
  isRoundedDropDown = false,
  currentFilterClassName = '',
  filtersCommonClassName = '',
  disabledItems,
  onClear,
  ...rest
}) {
  const selectRef = useRef(null);
  const isOpenRef = useRef(false);
  const clickOutsideRef = useClickOutside({
    onClickOutside: () => {
      isOpenRef.current = false;
    },
  });

  const handleIconClick = () => {
    const { open, setOpen } = selectRef.current;
    if (!isOpenRef.current && !open) {
      setOpen(true);
      isOpenRef.current = true;
    } else {
      isOpenRef.current = false;
    }
  };

  return (
    <div
      ref={clickOutsideRef}
      id={id}
      role="button"
      className={`select-with-custom-suffix-icon ${currentFilterClassName} ${filtersCommonClassName} ${
        isRoundedDropDown ? 'rounded-dropdown' : ''
      }`}
    >
      <CustomIcon
        onChange={onChange}
        value={selectedOption?.value}
        isClearable={isClearable}
        onClear={onClear}
        isRemoveable={isRemoveable}
        onRemove={onRemove}
        onIconClick={handleIconClick}
      />
      <SelectAndCreateOptionDropdown
        ref={selectRef}
        options={options ?? []}
        onChange={onChange}
        selectedOption={selectedOption}
        placeholder={placeholder}
        labelInValue
        showSearch={false}
        suffixIcon={null}
        disabledItems={disabledItems}
        useParentAsPopupContainer={false}
        {...rest}
      />
    </div>
  );
}

export default SelectWithCustomSuffixIcon;
