/* eslint-disable no-return-await */
import {
  CUSTOM_SURVEY_FORMS_API,
  ENTITY_SURVEY_API,
  SURVEYS_API,
  SURVEYS_LIST_API,
  SURVEY_DUPLICATE_API,
  SURVEY_TEMPLATES_API,
  TEMPALTE_SURVEY_FORMS_API,
} from './urls';
import {
  deleteAPICall,
  duplicateAPICall,
  getAPICall,
  postAPICall,
  putAPICall,
} from './utils';

export async function surveyCreateAPICall(survey) {
  return await postAPICall(ENTITY_SURVEY_API, survey);
}

export async function surveyUpdateAPICall(id, survey) {
  return await putAPICall(SURVEYS_API(id), survey);
}

export async function surveyTemplateCreateAPICall(survey) {
  return await postAPICall(SURVEY_TEMPLATES_API, survey);
}

export async function templateSurveyFormsListAPICall(params) {
  return await getAPICall(TEMPALTE_SURVEY_FORMS_API, params);
}

export async function customSurveyFormsListAPICall(params) {
  return await getAPICall(CUSTOM_SURVEY_FORMS_API, params);
}

export async function surveyTemplatesListAPICall() {
  return await getAPICall(SURVEY_TEMPLATES_API);
}

export async function getEntitySurveysAPICall() {
  return await getAPICall(ENTITY_SURVEY_API);
}

export async function getSurveyAPICall(id = '') {
  return await getAPICall(`${SURVEYS_LIST_API}${id}`);
}

export async function deleteSurveyAPICall(id) {
  return await deleteAPICall(`${SURVEYS_LIST_API}${id}/`);
}

export async function updateSurveyAPICall(id, survey) {
  return await putAPICall(`${SURVEYS_LIST_API}${id}`, survey);
}

export async function duplicateSurveyAPICall(id) {
  return await duplicateAPICall(SURVEY_DUPLICATE_API(id));
}
