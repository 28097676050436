import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createQrAPICall,
  deleteQrAPICall,
  getQrAPICall,
  initializeQrAPICall,
  printAllQrsAPICall,
  qrListAPICall,
  shareQrAPICall,
  updateQrAPICall,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const getQrList = createAsyncThunk(
  'qrSetup/getQrList',
  async (reviewId) => qrListAPICall(reviewId).catch(showErrorToast),
);

export const createQr = createAsyncThunk('qrSetup/createQr', async (data) =>
  createQrAPICall(data)
    .then((res) => {
      toast.success('QR has been saved');
      return res;
    })
    .catch(showErrorToast),
);

export const initializeQr = createAsyncThunk(
  'qrSetup/initializeQr',
  async (params) => initializeQrAPICall(params).catch(showErrorToast),
);

export const getQr = createAsyncThunk('qrSetup/getQr', async (params) =>
  getQrAPICall(params).catch(showErrorToast),
);

export const updateQr = createAsyncThunk(
  'qrSetup/updateQr',
  async ({ id, params }) =>
    updateQrAPICall(id, params)
      .then((res) => {
        toast.success('QR has been saved');
        return res;
      })
      .catch(showErrorToast),
);

export const updateQrListRow = createAsyncThunk(
  'qrSetup/updateQrListRow',
  async ({ id, params, columnKey, serialNumber }) =>
    updateQrAPICall(id, params)
      .then((res) => {
        let message = `QR ${serialNumber} has been updated`;
        if (columnKey === 'is_active') {
          message = `QR ${serialNumber} has been ${
            params.is_active ? 'activated' : 'deactivated'
          } `;
        }
        toast.success(message);
        return res;
      })
      .catch(showErrorToast),
);

export const deleteQr = createAsyncThunk('qrSetup/deleteQr', async (id) =>
  deleteQrAPICall(id)
    .then(() => {
      toast.success('QR has been deleted');
    })
    .catch(showErrorToast),
);

export const shareQr = createAsyncThunk('qrSetup/shareQr', async (data) =>
  shareQrAPICall(data)
    .then(() => {
      toast.success('QR has been shared');
    })
    .catch(showErrorToast),
);

export const printAllQrs = createAsyncThunk('qrSetup/printAllQrs', async () =>
  printAllQrsAPICall()
    .then((res) => {
      const pdfBlob = new Blob([res], { type: 'application/pdf' });
      return pdfBlob;
    })
    .catch(() =>
      showErrorToast({
        message:
          'Failed to generate PDF. Please make sure QR codes are linked.',
      }),
    ),
);
