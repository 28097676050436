import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import WarningModal from 'components/ui/modal/WarningModal';
import { fetchGoogleMapsReviews } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { changeBranchStatus } from 'redux/settings/settingsActions';

const useUpdateLocationQuery = (id, status) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onStatusChange = async () => {
    setIsLoading(true);
    const { error: { message } = {}, payload } = await dispatch(
      changeBranchStatus({ entities: [id], activate: status }),
    );
    setIsLoading(false);
    return { errorMessage: message, updatedLocation: payload };
  };

  return { isLoading, onUpdate: onStatusChange };
};

const statusTranslationKey = 'settings.locations.changeStatusModal';
const googleReviewsTranslationKey =
  'settings.locations.googleReviewsPrice.retrive';

const LocationStatusToggleModal = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState({});
  const [onSuccess, setOnSuccess] = useState();
  const [googleReviewsMessage, setGoogleReviewsMessage] = useState(null);

  const { isLoading, onUpdate } = useUpdateLocationQuery(
    location.id,
    !location.is_active,
  );

  const plan = useSelector((state) => {
    const {
      payments: {
        subscribedPlan: { data },
      },
    } = state;
    return state.payments.paymentPlans.plans?.find(
      (p) => p.title === data?.name,
    );
  });

  const cost = (plan?.cost_per_branch ?? 0) * (location.is_active ? -1 : 1);

  const onClose = () => {
    if (!isLoading) setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open(loc, onChange) {
      setIsOpen(!isOpen);
      setLocation(loc);
      setOnSuccess(() => onChange);
    },
  }));

  const toggleStatus = async () => {
    const { errorMessage, updatedLocation } = await onUpdate();
    if (errorMessage) toast.error(errorMessage);
    else {
      if (updatedLocation.google_reviews_message && updatedLocation.is_active) {
        setGoogleReviewsMessage(updatedLocation.google_reviews_message);
      } else {
        setIsOpen(false);
      }
      onSuccess(updatedLocation);
    }
  };

  const handleFetchGoogleReviews = (isFutureOnly) => {
    onClose();
    setGoogleReviewsMessage(null);
    dispatch(
      fetchGoogleMapsReviews({
        is_future_only: isFutureOnly,
        entity_id: location.id,
      }),
    );
  };

  const statusText = t(
    `settings.locations.${location.is_active ? 'deactivate' : 'activate'}`,
  );

  const subTitle = googleReviewsMessage ? (
    <p className="m-0">{googleReviewsMessage}</p>
  ) : (
    <>
      <p>
        {t(`${statusTranslationKey}.subTitle`, {
          status: statusText,
        })}
      </p>
      <p className="m-0">
        {cost === 0
          ? t(`${statusTranslationKey}.noExtraCharges`)
          : cost < 0
          ? t(`${statusTranslationKey}.saving`, {
              amount: Math.abs(cost),
            })
          : t(`${statusTranslationKey}.paying`, {
              amount: cost,
            })}
      </p>
    </>
  );
  return isOpen ? (
    <WarningModal
      wrapperStyles={{ zIndex: 1250 }}
      bodyStyles={{ width: 550 }}
      title={t(
        `${
          googleReviewsMessage
            ? googleReviewsTranslationKey
            : statusTranslationKey
        }.title`,
      )}
      subTitle={subTitle}
      className="google-reviews-price--warning-modal"
      {...(googleReviewsMessage
        ? {
            primaryActionTitle: t(`${googleReviewsTranslationKey}.futureOnly`),
            primaryAction: () => handleFetchGoogleReviews(true),
            secondaryActionTitle: t(`${googleReviewsTranslationKey}.fetchAll`),
            secondaryAction: () => handleFetchGoogleReviews(false),
          }
        : {
            primaryActionTitle: statusText,
            primaryAction: toggleStatus,
            disablePrimaryAction: isLoading,
            primaryActionLoading: isLoading,
            secondaryActionTitle: 'Cancel',
            secondaryAction: onClose,
            disableSecondaryAction: isLoading,
          })}
    />
  ) : null;
});

export default LocationStatusToggleModal;
