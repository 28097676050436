import { useState } from 'react';
import { toast } from 'react-toastify';

import { entityCreateAPICallV2, entityUpdateAPICallV2 } from 'apis/cxmeter';

export default function useBranchUpdateQuery(branchId, onComplete) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  return {
    isLoading,
    onSubmit: (
      { country, state, ...payload },
      { setSubmitting, setStatus },
    ) => {
      setIsLoading(true);
      (branchId
        ? entityUpdateAPICallV2(branchId, payload)
        : entityCreateAPICallV2(payload)
      )
        .then((res) => {
          toast.success(
            `Location ${branchId ? 'updated' : 'created'} successfully`,
          );
          onComplete(res);
        })
        .catch((error) => {
          if (error.response) {
            const { status, data } = error.response ?? {};
            if (status === 500 || (status === 400 && data?.timeout)) {
              setStatus({
                success: false,
                message:
                  data?.timeout ??
                  'We encountered an issue processing your request as it took longer than expected. Please try again later. If the issue persists, contact support for assistance.',
              });
            }
          }
          setErrors(error);
        })
        .finally(() => {
          setSubmitting(false);
          setIsLoading(false);
        });
    },
    errors,
  };
}
