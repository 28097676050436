import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert } from 'antd';

import BillingInformation from 'components/paymentDashboard/BillingInformation';
import CurrentPlan from 'components/paymentDashboard/CurrentPlan';
import PaymentMethods from 'components/paymentDashboard/PaymentMethods';
import { Spinner } from 'components/ui';
import { deltaDaysFromNow } from 'utils/helpers';

import { STRIPE_CLIENT_ID } from '../../config';

import './_PaymentDashboard.scss';

const stripePromise = loadStripe(STRIPE_CLIENT_ID);

export default function PaymentDashboard() {
  const { t } = useTranslation();
  const { subscribedPlan } = useSelector((s) => s.payments);

  return (
    <div
      className="settings-non-table-container payment-dashbord-container"
      style={{ opacity: subscribedPlan?.subscribedPlanLoading ? 0.5 : 1 }}
    >
      {subscribedPlan?.subscribedPlanLoading ? (
        <Spinner isTransparentBackground />
      ) : null}
      <div className="settings-non-table-container-header">
        <h3>{t('settings.payments.title')}</h3>
        <p>{t('settings.payments.subTitle')}</p>
      </div>
      <div
        className="settings-non-table-container-content"
        style={{ position: 'relative' }}
      >
        {subscribedPlan?.data?.is_trial_active && (
          <Alert
            message={t('settings.payments.plans.remainingTrialPeriod', {
              days: deltaDaysFromNow(subscribedPlan.data.next_invoice_date),
            })}
            type="warning"
            icon={<img src="/assets/icons/icon_warning.svg" alt="Warn" />}
            showIcon
            className="payment-dashboard__trial-message"
          />
        )}
        <CurrentPlan />
        <BillingInformation />
        <Elements
          stripe={stripePromise}
          appearance={{
            theme: 'flat',
          }}
        >
          <PaymentMethods />
        </Elements>
      </div>
    </div>
  );
}
