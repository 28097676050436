import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Menu as AntDMenu, ConfigProvider, Drawer } from 'antd';
import { isEmpty } from 'lodash';

import { Icon } from 'components/ui';
import {
  findSettingsActiveMenu,
  settingMenuOpenKeysList,
} from 'utils/helpers/settingsHelpers';

import './_settingTabs.scss';

function SettingTabs({ children }) {
  return <div className="cxmeter-settings">{children}</div>;
}

function MenuContent({ setDrawerVisible, menuItems }) {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const [selectedKeys, setSelectedKeys] = useState([]);

  const onClick = (e) => {
    if (setDrawerVisible) setDrawerVisible(false);
    setSelectedKeys([e.key]);
  };

  useEffect(() => {
    if (setDrawerVisible) setDrawerVisible(false);
    if (!state?.isSettingMenuLink || isEmpty(selectedKeys)) {
      navigate(pathname, {
        replace: true,
        state: { ...(state ?? {}), isSettingMenuLink: false },
      });
      const selectedKey = findSettingsActiveMenu(menuItems, pathname);
      if (selectedKey) setSelectedKeys([selectedKey]);
    }
  }, [pathname]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemMarginInline: 0,
            itemBg: 'transparent',
            itemColor: 'black',
            itemHoverColor: '#de0024',
            itemHoverBg: '#de002414',
            itemSelectedColor: '#de0024',
            itemSelectedBg: '#de002414',
            itemActiveBg: 'transparent',
          },
        },
      }}
    >
      <AntDMenu
        expandIcon={null}
        className="cxmeter-settings-tabs"
        onClick={onClick}
        inlineIndent={20}
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={settingMenuOpenKeysList}
        items={menuItems}
      />
    </ConfigProvider>
  );
}

function MobileMenu({ menuItems }) {
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <div className="cxmeter-mobile-navbar">
      <button onClick={() => setDrawerVisible(true)}>
        <Icon type="menu" />
      </button>
      <Drawer
        title="Settings Menu"
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        style={{ padding: 0 }}
      >
        <MenuContent
          menuItems={menuItems}
          setDrawerVisible={setDrawerVisible}
        />
      </Drawer>
    </div>
  );
}

function DesktopMenu({ menuItems }) {
  return (
    <div className="cxmeter-desktop-navbar">
      <MenuContent menuItems={menuItems} />
    </div>
  );
}

function Content({ children }) {
  return <article className="cxmeter-settings-content">{children}</article>;
}

SettingTabs.DesktopMenu = DesktopMenu;
SettingTabs.MobileMenu = MobileMenu;
SettingTabs.Content = Content;
export default SettingTabs;
