export const getPlaceAddressComponents = (place) => {
  let city;
  let state;
  let country;
  let zipCode;

  place.address_components.forEach((component) => {
    if (component.types.includes('locality')) {
      city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      state = component.long_name;
    }
    if (component.types.includes('country')) {
      country = component.long_name;
    }
    if (component.types.includes('postal_code')) {
      zipCode = component.long_name;
    }
  });

  return { city, state, country, zip_code: zipCode };
};

export const getPlaceDetailsById = (placeId) => {
  return new Promise((resolve, reject) => {
    if (!window.google) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Google Maps API is not available');
      return;
    }

    const service = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    );

    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        resolve(place);
      } else {
        reject(status);
      }
    });
  });
};
