/* eslint-disable no-shadow */

/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import leven from 'leven';
import { isEmpty, lowerCase } from 'lodash';

import { SortableItem } from 'components/dragAndDrop';
import Ratings from 'components/ratings/Ratings';
import RichTextEditor from 'components/richTextEditor/RichTextEditor';
import { CHECKBOX, RATING } from 'utils/constants/questionTypes';
import { arrayMove } from 'utils/helpers';
import { formatSurveyOption, getOptionId } from 'utils/helpers/surveyHelpers';

import './_surveyBuilderAnswers.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function MultipleChoiceAnswer({
  sectionId,
  questionId,
  type,
  options = [],
  setOptions,
  isCompulsoryQuestion,
  onMaxAllowedAnswersChange,
  maxAllowedAnswers = 3,
  similarityThreshold = 80,
  setIsSimilarErrorActive,
  optionsErrors = [],
  duplicateOptionsSections,
}) {
  const [currentChoice, setCurrentChoice] = useState({});
  const [similarityErrorIds, setSimilarityErrorIds] = useState({});

  const { t } = useTranslation();

  const handleOptionAdd = () => {
    if (currentChoice?.label?.hasText()) {
      const choiceId = getOptionId(questionId, options.length);
      const choice = formatSurveyOption(
        choiceId,
        currentChoice.option_id,
        currentChoice.id ? '' : currentChoice.label,
      );
      setOptions([...options, choice]);
      setCurrentChoice({ label: null });
    }
  };

  const getStringSimilarity = (str1, str2) => {
    const distance = leven(str1, str2);
    const maxLength = Math.max(str1.length, str2.length);
    const similarity = ((maxLength - distance) / maxLength) * 100;
    return similarity.toFixed(2); // Returns similarity percentage with 2 decimal places
  };

  const checkSimilarity = (options, currentOption, id) => {
    duplicateOptionsSections.current = {};
    let isSimilar = false;
    currentOption = lowerCase(currentOption);

    options.forEach((option) => {
      const similarity = getStringSimilarity(
        lowerCase(option.label.getPlainText()),
        currentOption,
      );

      if (similarity > similarityThreshold) {
        setIsSimilarErrorActive(true);

        /*
        Example:
        - Initial State: {}
        - Option 1 is similar to Option 2:
          - {1: [2], 2:[1]}
        - Option 2 is similar to Option 3:
          - {1: [2], 2:[1, 3], 3: [2]}
        - Option 2 is no longer similar to Option 3:
          - {1: [2], 2:[1], 3:[]}
        - Bidirectional relation ensures that Option 2 is still in similar list
        */
        setSimilarityErrorIds((state) => {
          // add option to prev graph
          state[id] = state[id] || {};
          state[id][option.id] = {
            similarity: +similarity,
          };

          // add current to option graph
          state[option.id] = state[option.id] || {};
          state[option.id][id] = {
            similarity: +similarity,
          };

          return { ...state };
        });

        isSimilar = true;
      } else if (!isSimilar) {
        setSimilarityErrorIds((state) => {
          // remove option from prev graph
          if (state[id]) {
            delete state[id][option.id];
            if (Object.keys(state[id]).length === 0) {
              delete state[id];
            }
          }
          // remove current from option graph
          if (state[option.id]) {
            delete state[option.id][id];
            if (Object.keys(state[option.id]).length === 0) {
              delete state[option.id];
            }
          }

          return state;
        });
      }
    });
  };

  const handleSetChoice = (id, option_id) => (choice) => {
    let otherOptions;
    if (id) {
      otherOptions = options.filter((option) => option.id !== id);
      const idx = options.findIndex((ch) => ch.id === id);
      const temp = [...options];
      temp[idx] = formatSurveyOption(id, option_id, choice);
      setOptions(temp);
    } else {
      otherOptions = options;
      setCurrentChoice({ id, label: choice });
    }
    const currentChoiceText = choice.getPlainText();
    checkSimilarity(otherOptions, currentChoiceText, id);

    if (
      isEmpty(similarityErrorIds) ||
      Object.values(similarityErrorIds).every(
        (similarIds) => similarIds.length === 0,
      )
    ) {
      setIsSimilarErrorActive(false);
    }
  };

  const handleRemoveChoice = (optionIdx, optionId) => {
    if (isCompulsoryQuestion && options.length <= 3) {
      toast.warn('At least 3 options are required for this question.');
      return;
    }
    const temp = [...options];
    temp.splice(optionIdx, 1);
    setOptions(temp);

    const tempSimilarityErrorIds = { ...similarityErrorIds };

    Object.entries(tempSimilarityErrorIds).forEach(([source, target]) => {
      if (source === optionId) {
        delete tempSimilarityErrorIds[source];
      } else if (target[optionId]) {
        delete target[optionId];
        if (Object.keys(target).length === 0) {
          delete tempSimilarityErrorIds[source];
        }
      }
    });

    duplicateOptionsSections.current = {};
    setSimilarityErrorIds(tempSimilarityErrorIds);
    if (
      isEmpty(tempSimilarityErrorIds) ||
      Object.values(tempSimilarityErrorIds).every(
        (similarIds) => Object.keys(similarIds).length === 0,
      )
    ) {
      setIsSimilarErrorActive(false);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const itemIds = useMemo(() => options.map((item) => item.id), [options]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      setOptions(arrayMove(options, active.id, over.id));
    }
  };

  const getSimilarityError = (choice) => {
    const similarOptions = similarityErrorIds[choice.id];
    let error = '';
    if (similarOptions && Object.keys(similarOptions).length > 0) {
      const isDuplicateOption = Object.values(similarOptions).find((opt) => {
        error = t('formBuilder.similarity.error');
        return opt.similarity >= 100;
      });
      if (isDuplicateOption) {
        duplicateOptionsSections.current[sectionId] = true;
        error = t('formBuilder.similarity.duplicateError');
      }
    }
    return error;
  };

  useEffect(() => {
    if (maxAllowedAnswers === 0) {
      onMaxAllowedAnswersChange(options.length);
    }
  }, [maxAllowedAnswers]);

  return (
    <div>
      {type === CHECKBOX ? (
        <label style={{ marginTop: '1rem' }}>
          <span>Max Allowed Choices: </span>
          <input
            type="number"
            min={1}
            max={options.length}
            value={maxAllowedAnswers}
            onChange={({ target: { value } }) => {
              value = value ? Number.parseInt(value, 10) : options.length;
              onMaxAllowedAnswersChange(value);
            }}
            style={{ width: '3rem', textAlign: 'center' }}
          />
        </label>
      ) : null}
      <DndContext
        id="choices-dnd-context"
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {options?.map((choice, index) => {
            const error =
              optionsErrors[index]?.error ?? getSimilarityError(choice);
            return (
              <div key={index} className="choiceDragWrapper">
                <SortableItem key={choice.id} id={choice.id}>
                  <div className="choiceWrapper">
                    <div className="editor-container">
                      <RichTextEditor
                        setState={handleSetChoice(choice.id, choice.option_id)}
                        content={choice.label}
                        error={error}
                        focusEditor={index + 1 === options.length}
                      />
                    </div>
                    {type === RATING ? (
                      <Ratings disabled />
                    ) : (
                      <input
                        type={type}
                        id={`${type}-input-${choice.id}`}
                        disabled
                        className="choiceInput"
                      />
                    )}
                    {(!isCompulsoryQuestion || index > 2) &&
                      options?.length > 1 && (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="crossIcon"
                          onClick={() => handleRemoveChoice(index, choice.id)}
                        />
                      )}
                  </div>
                </SortableItem>
              </div>
            );
          })}
        </SortableContext>
      </DndContext>
      <div className="last-choice">
        <span className="mock-dnd-icon" />
        <div className="last-choice-data-section">
          <span className="choiceWrapper">
            <div className="editor-container">
              <RichTextEditor
                setState={handleSetChoice(null, null)}
                content={currentChoice.label}
                className="choiceInput"
                onChange={handleOptionAdd}
              />
            </div>
            {type === RATING ? (
              <Ratings disabled />
            ) : (
              <input type={type} disabled className="choiceInput" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default MultipleChoiceAnswer;
