import React, { useEffect, useRef } from 'react';

import mapboxgl from 'mapbox-gl';

import { MAP_BOX_CLIENT_ID } from '../config';

import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line
mapboxgl.accessToken = MAP_BOX_CLIENT_ID;

// pin = [lng, lat]
export default function Map({
  pin,
  showMarker = true,
  mapStyles = {},
  zoom = 9,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);

  const createMap = (lat, long) => {
    return new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lat, long],
      zoom,
    });
  };

  useEffect(() => {
    if (pin?.length) {
      map.current ??= createMap(...pin);
      map.current.flyTo({ center: pin, zoom });
      if (showMarker) {
        if (marker.current) {
          marker.current.setLngLat(pin);
        } else {
          marker.current = new mapboxgl.Marker({ color: 'black' })
            .setLngLat(pin)
            .addTo(map.current);
        }
      }
    }
  }, [pin, showMarker]);

  return (
    <div
      ref={mapContainer}
      style={{ height: '100%', ...mapStyles }}
      className="map-container"
    />
  );
}
