import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, Switch } from 'antd';
import dayjs from 'dayjs';

import { Modal } from 'components/ui';
import WarningModal from 'components/ui/modal/WarningModal';
import { deleteOrgApiKey, getOrgApiKey } from 'redux/settings/settingsActions';
import { copyToClipboard } from 'utils/helpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

const modalTranslationKey = `settings.organization.apiKeyModal`;

function OrgApiKeyModal({ isOpen, onClose, formik }) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const { values: apiKey = {}, errors, setFieldValue } = formik;

  const calendarPopupRef = useRef(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [loading, setLoading] = useState({ update: false, delete: false });

  const handleDeleteApiKey = async () => {
    setShowDeleteWarning(false);
    setLoading((p) => ({ ...p, delete: true }));
    await dispatch(deleteOrgApiKey(apiKey.id), formik, {
      nextAction: getOrgApiKey,
    });
    setLoading((p) => ({ ...p, delete: false }));
    onClose();
  };

  const handleUpdateApiKey = async () => {
    setLoading((p) => ({ ...p, update: true }));
    await formik.submitForm();
    setLoading((p) => ({ ...p, update: false }));
  };

  const handleCopyApiKey = () => {
    copyToClipboard(apiKey.key, 'API Key');
  };

  const onCloseModal = () => {
    if (loading.delete || loading.update) return;
    onClose();
  };

  const disablePastDates = (current) => {
    return current && current < dayjs().add(1, 'day').startOf('day');
  };

  return (
    <Modal
      className="org-api-key-modal"
      onClose={onCloseModal}
      open={isOpen}
      isCurveredBorderedModal
      modalRef={calendarPopupRef}
      backDropClose={false}
    >
      <Modal.Header
        title={t(`${modalTranslationKey}.title`)}
        onClose={onCloseModal}
      />
      <Modal.Body>
        <div className="org-api-key-detail">
          {apiKey.isNewKey ? (
            <div className="org-api-key-instruction-container">
              <div className="org-api-key-instruction">
                <div className="code-icon">
                  <FontAwesomeIcon style={{ color: '#2F80ED' }} icon={faCode} />
                </div>
                <div>
                  <h6>{t(`${modalTranslationKey}.copyAPIKeyTitle`)}</h6>
                  <p>{t(`${modalTranslationKey}.copyAPIKeySubTitle`)}</p>
                </div>
              </div>
              <hr style={{ marginBlock: '2rem' }} />
              <div className="copy-api-key-container">
                <p>{apiKey.key}</p>
                <button
                  onClick={handleCopyApiKey}
                  className="cstm-btn cstm-secondary"
                >
                  <img src="/assets/icons/icon_copy.svg" alt="Copy" />
                  {t(`${modalTranslationKey}.copy`)}
                </button>
              </div>
            </div>
          ) : null}
          <div className="org-api-key-inputs">
            <div className="org-api-key-expiry-date">
              <p>{t(`${modalTranslationKey}.expiresOn`)}</p>
              <DatePicker
                inputReadOnly
                disabledDate={disablePastDates}
                placeholder="Select date"
                format="DD MMM YYYY"
                className="org-api-key-expiry-datepicker"
                value={
                  apiKey.expiration_date ? dayjs(apiKey.expiration_date) : null
                }
                getPopupContainer={() => calendarPopupRef.current}
                onChange={(date) => setFieldValue('expiration_date', date)}
                style={{ height: 40 }}
              />
              {errors.expiration_date && (
                <span className="control-error">{errors.expiration_date}</span>
              )}
            </div>
            <div className="org-api-key-expiry-status">
              <p>{t(`${modalTranslationKey}.status`)}</p>
              <div>
                <Switch
                  name="org-api-key-expiry-status"
                  className="primary-antd-switch-input"
                  onChange={(isChecked) =>
                    setFieldValue('is_active', isChecked)
                  }
                  checked={apiKey.is_active}
                />
                <span>{apiKey.is_active ? 'Active' : 'Inactive'}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        isCurveredBorderedModal
        primaryAction={handleUpdateApiKey}
        primaryLabel={t('settings.organization.submit')}
        disablePrimaryAction={loading.delete || loading.update}
        isLoading={loading.update}
        tertiaryAction={() => setShowDeleteWarning(true)}
        tertiaryLabel={t(`${modalTranslationKey}.deleteKey`)}
        tertiaryActionIcon={
          <img src="/assets/icons/icon_bin.svg" alt="Delete" />
        }
        disableTertiaryAction={loading.delete || loading.update}
        tertiaryLoading={loading.delete}
      />
      {showDeleteWarning ? (
        <WarningModal
          title={t(`${modalTranslationKey}.deleteKeyWarning.title`)}
          subTitle={t(`${modalTranslationKey}.deleteKeyWarning.subTitle`)}
          primaryAction={handleDeleteApiKey}
          primaryActionTitle={t(`${modalTranslationKey}.deleteKeyWarning.yes`)}
          secondaryAction={() => setShowDeleteWarning(false)}
          secondaryActionTitle={t(
            `${modalTranslationKey}.deleteKeyWarning.cancel`,
          )}
        />
      ) : null}
    </Modal>
  );
}

export default OrgApiKeyModal;
