export const BACKEND_URL = 'https://ss.sentimeter.io';
export const REACT_APP_GOOGLE_ANALYTICS_KEY = 'G-9HLE62Q081';
export const REACT_APP_GOOGLE_TAG_KEY = 'GTM-MMR67VP';
export const GOOGLE_WEB_CLIENT_ID =
  '896248958716-v6udvmpe3huv92ur20k97ig17k86i3b2.apps.googleusercontent.com';

export const MAP_BOX_CLIENT_ID =
  'pk.eyJ1IjoiaGFtemEwMTIiLCJhIjoiY2xpMXAzMzIxMDZweDNqcGdjcDVoZDl4OCJ9.UwpDT5DcPn7AoG3hzprNJA';

export const STRIPE_CLIENT_ID =
  'pk_test_51N3b6eFH3nn64Zk7cZjMb7iKjQLaAjopDhTGw80UZ7xAQun1nQKf4GkjuG80BYcYTvV2h9ig9vuAa3Bue3MWHviq00Tc4tXwUd';

export const PLAYSTORE_APP_LINK = '#';
export const APPSTORE_LINK = '#';

export const SAAS_AGREEMENT_LAST_MODIFIED = 'January 30th, 2024';
export const SAAS_AGREEMENT_VERSION = '1.0';

export const CXMETER_SURVEY_TITLE = 'CXMeter Survey';
export const CXMETER_SUBSCRIPTION_TITLE = 'Subscription Cancellation Form';

export const ENV = 'DEV';

export const EXTREME_IP_LOOKUP_API_KEY = 'nuRjD89EQOPOCRXbn5Z9';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDbxZ4FXFwDcO_aydTBWVD-3MseZoblLe0';
