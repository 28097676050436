import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Groups from 'components/manageEmails/Groups';
import Inbox from 'components/manageEmails/Inbox/Inbox';
import InboxSubTabs from 'components/manageEmails/Inbox/InboxSubTabs/InboxSubTabs';
import Schedules from 'components/manageEmails/Schedule';
import Templates from 'components/manageEmails/Templates';
import SendEmailModal from 'components/manageEmails/modals/ComposeEmail/SendEmailModal';
import InviteGroupModal from 'components/manageEmails/modals/InviteGroup/InviteGroupModal';
import InviteTemplateModal from 'components/manageEmails/modals/InviteTemplate/InviteTemplateModal';
import { SettingsPageContext } from 'contexts';
import SettingsPageContextProvider from 'layouts/settingsPageLayout/SettingsPageContextProvider';
import SettingsPageLayout from 'layouts/settingsPageLayout/SettingsPageLayout';
import {
  EMAIL_GROUPS,
  EMAIL_TEMPLATES,
  INBOX_EMAILS,
  MANAGE_EMAILS_TABS,
  MANAGE_EMAIL_SCHEDULE,
} from 'utils/constants/manageEmails';

import './_common.scss';
import './_manageEmails.scss';

export const ManageEmailsContext = createContext();

function EmailData() {
  const { t } = useTranslation();
  const { activeTab } = useContext(SettingsPageContext);

  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const inviteEmailDataRef = useRef();

  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
  const inviteGroupIdRef = useRef();
  const [isOpenInviteTemplateModal, setIsOpenInviteTemplateModal] =
    useState(false);
  const inviteTemplateIdRef = useRef();

  const manageEmailContextValues = useMemo(
    () => ({
      isOpenSendEmailModal,
      setIsOpenSendEmailModal,
      inviteEmailDataRef,
      isOpenGroupModal,
      setIsOpenGroupModal,
      inviteGroupIdRef,
      isOpenInviteTemplateModal,
      setIsOpenInviteTemplateModal,
      inviteTemplateIdRef,
    }),
    [
      isOpenSendEmailModal,
      setIsOpenSendEmailModal,
      inviteEmailDataRef,
      isOpenGroupModal,
      setIsOpenGroupModal,
      inviteGroupIdRef,
      isOpenInviteTemplateModal,
      setIsOpenInviteTemplateModal,
      inviteTemplateIdRef,
    ],
  );

  return (
    <ManageEmailsContext.Provider value={manageEmailContextValues}>
      <SettingsPageLayout
        pageTitle={t('settings.manageEmails.title')}
        pageSubTitle={t('settings.manageEmails.subTitle')}
        tabs={MANAGE_EMAILS_TABS}
      >
        {{
          generalAction: activeTab.isActiveSubTab ? null : (
            <div className="controls">
              <button
                className="create-new-group cstm-btn secondary-cstm-btn"
                size="large"
                onClick={() => setIsOpenGroupModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>
                  {t('settings.manageEmails.controls.createNewGroup')}
                </span>
              </button>
              <button
                className="compose-new cstm-btn primary-cstm-btn"
                size="large"
                onClick={() => setIsOpenSendEmailModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{t('settings.manageEmails.controls.composeNew')}</span>
              </button>
            </div>
          ),
          currentTabActions:
            activeTab.id === EMAIL_TEMPLATES ? (
              <button
                onClick={() => setIsOpenInviteTemplateModal(true)}
                className="create-new-tmeplate cstm-btn secondary-cstm-btn"
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Create Template</span>
              </button>
            ) : null,
          currentTabContent: (
            <Routes>
              <Route index element={<Navigate to={INBOX_EMAILS} replace />} />
              <Route path={INBOX_EMAILS} element={<Inbox />}>
                <Route path=":subTabRecordId" element={<InboxSubTabs />} />
              </Route>
              <Route path={EMAIL_GROUPS} element={<Groups />} />
              <Route path={MANAGE_EMAIL_SCHEDULE} element={<Schedules />} />
              <Route path={EMAIL_TEMPLATES} element={<Templates />} />
              <Route
                path="*"
                element={<Navigate to={INBOX_EMAILS} replace />}
              />
            </Routes>
          ),
        }}
      </SettingsPageLayout>
      {isOpenGroupModal ? <InviteGroupModal /> : null}
      {isOpenSendEmailModal ? <SendEmailModal /> : null}
      {isOpenInviteTemplateModal ? <InviteTemplateModal /> : null}
    </ManageEmailsContext.Provider>
  );
}

function ManageEmails() {
  return (
    <SettingsPageContextProvider tabs={MANAGE_EMAILS_TABS}>
      <EmailData />
    </SettingsPageContextProvider>
  );
}

export default ManageEmails;
