import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WidgetsConfigModal from 'components/modals/WidgetsConfigModal';
import {
  getConfigWidgets,
  postConfigWidgets,
} from 'redux/dashboard/dashboardActions';
import {
  setConfigWidgets,
  setEnabledWidgets,
} from 'redux/dashboard/dashboardSlice';

import './_configurationPanel.scss';

function ConfigurationPanel({ isOpen, setIsOpen }) {
  const [isDirty, setIsDirty] = useState(false);
  const { isLoading, data } = useSelector(
    (state) => state.dashboard.configWidgets,
  );

  const dispatch = useDispatch();

  const handleCloseConfig = () => {
    setIsOpen(false);
  };

  const handleSetEnabledWidgets = (configData) => {
    const enabledWidgets = [];
    configData?.forEach((survey) => {
      survey.questions.forEach((question) => {
        if (question.is_enabled) {
          enabledWidgets.push({
            id: question.id,
            uuid: question.uuid,
          });
        }
      });
    });
    dispatch(setEnabledWidgets(enabledWidgets));
  };

  const toggleSingleSurveyQuestions = (checked, currentSurveyIndex) => {
    const tempSurveys = [...data];
    const currentSurvey = { ...tempSurveys[currentSurveyIndex] };
    currentSurvey.questions = currentSurvey.questions.map((q) => ({
      ...q,
      is_enabled: checked,
    }));
    tempSurveys[currentSurveyIndex] = currentSurvey;
    handleSetEnabledWidgets(tempSurveys);
    dispatch(setConfigWidgets(tempSurveys));
    setIsDirty(true);
  };

  const toggleQuestionSelection = (
    checked,
    currentSurveyIndex,
    questionIndex,
  ) => {
    const tempSurveys = [...data];
    const currentSurvey = {
      ...tempSurveys[currentSurveyIndex],
      questions: [
        ...tempSurveys[currentSurveyIndex].questions.slice(0, questionIndex),
        {
          ...tempSurveys[currentSurveyIndex].questions[questionIndex],
          is_enabled: checked,
        },
        ...tempSurveys[currentSurveyIndex].questions.slice(questionIndex + 1),
      ],
    };
    tempSurveys[currentSurveyIndex] = currentSurvey;

    handleSetEnabledWidgets(tempSurveys);
    dispatch(setConfigWidgets(tempSurveys));
    setIsDirty(true);
  };

  const handleSaveConfigurations = () => {
    const widgets = data.reduce((acc, val) => {
      acc.push(
        val.questions.map((v) => ({
          question: v.id,
          is_enabled: v.is_enabled,
        })),
      );
      return acc;
    }, []);
    dispatch(postConfigWidgets({ widgets: widgets.flat() }));
    setIsOpen(false);
    setIsDirty(false);
  };

  const handleCancelConfigurations = () => {
    dispatch(getConfigWidgets());
    handleSetEnabledWidgets(data);
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(getConfigWidgets());
  }, []);

  useEffect(() => {
    handleSetEnabledWidgets(data);
  }, [data]);

  return (
    <div>
      <div className="headerDropdownsWrapper spb">
        <span>Survey Drivers</span>
      </div>
      <WidgetsConfigModal
        data={data}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        isOpen={isOpen}
        isLoading={isLoading}
        handleSetEnabledWidgets={handleSetEnabledWidgets}
        toggleQuestionSelection={toggleQuestionSelection}
        toggleSingleSurveyQuestions={toggleSingleSurveyQuestions}
        onClose={handleCloseConfig}
        onCancel={handleCancelConfigurations}
        onSave={handleSaveConfigurations}
      />
    </div>
  );
}

export default ConfigurationPanel;
