import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';

import { ProfileAvatar } from 'components';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import { editProfile } from 'redux/authentication/authenticationActions';
import editProfileValidationSchema from 'utils/validations/editProfileValidation';

import './_editProfile.scss';

function EditProfile() {
  const { user, isLoading } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(user.profile_photo);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      profile_photo: '',
    },
    validationSchema: editProfileValidationSchema,
    onSubmit: async (values) => {
      const payload = { ...values };
      if (!payload.profile_photo || typeof payload.profile_photo === 'string') {
        delete payload.profile_photo;
      }
      delete payload.survey_skipped_at;
      const userFormData = new FormData();
      Object.entries(payload).forEach(([k, v]) => userFormData.append(k, v));
      dispatch(editProfile({ id: payload.id, formData: userFormData }));
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: user,
    });
  }, [user]);

  const handlePhotoUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.style.display = 'none';
    fileInput.onchange = (event) => {
      formik.setFieldValue('profile_photo', event.target.files[0]);
      const objectURL = URL.createObjectURL(event.target.files[0]);
      setPhotoUrl(objectURL);
    };
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  };

  return (
    <div className="settings-non-table-container">
      <div className="settings-non-table-container-header">
        <h3>Edit Profile</h3>
        <p>You can edit your profile here</p>
      </div>
      <div className="settings-non-table-container-content">
        <div
          className="avatar-container"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <ProfileAvatar
            size={96}
            photoUrl={photoUrl}
            firstName={formik.values.first_name}
            lastName={formik.values.last_name}
            userId={user.id}
          />
          <div className="actions">
            {isHovered && (
              <FontAwesomeIcon
                className="actionIcon"
                icon={faPencilAlt}
                onClick={handlePhotoUpload}
              />
            )}
          </div>
        </div>
        <form className="cxmeter-form small" onSubmit={formik.handleSubmit}>
          <div className="control-group">
            <FormikLabeledInput
              formik={formik}
              inputName="first_name"
              label="First Name"
              placeholder="First Name"
            />
            <FormikLabeledInput
              formik={formik}
              inputName="last_name"
              placeholder="Last Name"
              label="Last Name"
            />
          </div>
          <FormikLabeledInput
            formik={formik}
            placeholder="Email"
            inputName="email"
            label="Email"
            required={false}
            disabled
          />
        </form>
      </div>
      <div className="settings-non-table-container-footer">
        <button
          type="button"
          className="cstm-btn primary-cstm-btn"
          onClick={formik.handleSubmit}
          disabled={isLoading || !formik.dirty}
          aria-busy={isLoading}
        >
          Save Profile
        </button>
      </div>
    </div>
  );
}

export default EditProfile;
