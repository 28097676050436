import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { Icon } from 'components/ui';
import {
  downloadCSVTemplate,
  getInviteGroupsForEmailModal,
} from 'redux/emailManagement/emailManagementActions';
import { resetEmailModalInviteGroups } from 'redux/emailManagement/emailManagementSlice';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';
import { getEmailError } from 'utils/validations/common';

import EmailModalInput from './ComposeEmail/EmailModalInput';

function CSVFileInput({ values, setFieldValue }) {
  const fileInputRef = useRef();
  const clearSelectedFile = (e) => {
    e.stopPropagation();
    setFieldValue('members_file', null);
  };
  const csvFileHandler = (e) => {
    const selectedFile = e.target.files[0];
    setFieldValue('members_file', selectedFile);
  };
  const fileInputClickHandler = ({ target }) => {
    target.value = null;
  };
  const openFilePicker = () => {
    // Set file picker input value to null so that onChange event is fired every time
    fileInputRef.current.click();
  };

  return (
    <>
      <label
        className="upload-xls-input"
        style={{ cursor: 'pointer' }}
        role="presentation"
        onClick={openFilePicker}
      >
        <img src="/assets/images/add-users.svg" alt="Add Users" />
        <span className="xls-file-name text-ellipsis">
          {values.members_file?.name ?? 'Upload CSV'}
        </span>
        {values.members_file ? (
          <button className="clear-xls-file" onClickCapture={clearSelectedFile}>
            <Icon type="close" />
          </button>
        ) : null}
      </label>
      <input
        onClick={fileInputClickHandler}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={csvFileHandler}
        type="file"
        id="upload-csv"
        accept=".csv"
      />
    </>
  );
}

function ToEmailInput({ formik, isEmailModal = false }) {
  const dispatch = useDispatch();
  const emailInputRef = useRef(null);
  const {
    emailModalInviteGroups: { data: inviteGroups, loading },
  } = useSelector((s) => s.emailManagement);

  const [showGroupsSuggestions, setShowGroupsSuggestions] = useState(false);
  const selectedGroups = useRef();

  const { setFieldError, setFieldValue, values, errors } = formik;

  const addMemberEmail = (target) => {
    const email = target.value;
    const emailError = getEmailError(email);
    if (!emailError) {
      if (values.members.find((member) => member.email === email)) {
        setFieldError('invalidMembers', 'Duplicate email is not allowed');
        return { isEmailAdded: false, isDuplicate: true };
      }
      setFieldValue('members', [...values.members, { email }]);
      target.value = '';
      return { isEmailAdded: true, isDuplicate: false };
    }
    return {
      isEmailAdded: false,
      isDuplicate: false,
      errorMessage: emailError,
    };
  };

  const addEmailHandler = (e) => {
    formik.setErrors({ ...errors, members: null, invalidMembers: null });
    if (e.key === 'Enter') {
      const { isEmailAdded, isDuplicate, errorMessage } = addMemberEmail(
        e.target,
      );
      if (!isEmailAdded && !isDuplicate)
        setFieldError('invalidMembers', errorMessage);
    }
  };

  const onBlurEmailInput = ({ target }) => {
    const { value } = target;
    // When email input contains only blank spaces
    if (!value.trim()) {
      target.value = '';
      setFieldValue('emailInput', value);
      return;
    }
    const { isEmailAdded, isDuplicate } = addMemberEmail(target);
    if (!isEmailAdded && isDuplicate) {
      target.value = '';
    } else if (!isEmailAdded) {
      setFieldValue('emailInput', value);
    } else {
      // If Email was added on blur event
      setFieldValue('emailInput', '');
    }
  };

  const addGroupHandler = (group) => {
    setFieldValue('groups', [...(values.groups ?? []), group]);
    selectedGroups.current = { ...selectedGroups.current, [group.id]: true };
    setShowGroupsSuggestions(false);
  };

  const removeEmailOrGroupHandler = (index, key) => {
    const items = [...values[key]];
    const item = items[index];
    items.splice(index, 1);
    setFieldValue(key, items);
    if (key === 'groups') {
      delete selectedGroups.current[item.id];
    }
  };

  const getGroups = useCallback(
    debounce((value) => {
      dispatch(resetEmailModalInviteGroups());
      if (value && value.trim()) {
        dispatch(
          getInviteGroupsForEmailModal({
            ...PAGINATION_AND_SORTING_PARAMS,
            search: value,
          }),
        );
      }
    }, 300),
    [],
  );

  const emailInputChangeHandler = ({ target: { value } }) => {
    getGroups(value);
  };

  const downloadCSVSampleHandler = () => {
    dispatch(downloadCSVTemplate());
  };

  useEffect(() => {
    if (values.groups) {
      const groups = {};
      values.groups.forEach((g) => {
        groups[g.id] = true;
      });
      selectedGroups.current = groups;
    }

    return () => {
      dispatch(resetEmailModalInviteGroups());
    };
  }, []);

  const fieldError =
    errors.invalidMembers ||
    (formik.submitCount > 0 && (errors.members || errors.members_file));

  return (
    <div className="members-emails-container">
      {isEmailModal ? (
        <div>
          <div className="email-input-container">
            <EmailModalInput
              ref={emailInputRef}
              formik={formik}
              onKeyDown={addEmailHandler}
              id="email-input"
              label="To"
              placeholder="Enter email address or upload a CSV file"
              isTextInput={false}
              onBlur={onBlurEmailInput}
              onFocus={() => setShowGroupsSuggestions(true)}
              closeGroupSuggestions={() => setShowGroupsSuggestions(false)}
              onChange={emailInputChangeHandler}
              autoComplete="off"
            >
              {showGroupsSuggestions &&
              emailInputRef.current?.value &&
              !loading &&
              inviteGroups.results.length ? (
                <div className="group-suggestions">
                  {inviteGroups.results.map((group) => (
                    <button
                      onClick={() => addGroupHandler(group)}
                      className="group-name"
                      key={group.id}
                      disabled={selectedGroups.current[group.id]}
                    >
                      <span>{group.name}</span>
                      {selectedGroups.current[group.id] ? (
                        <Icon type="check" />
                      ) : null}
                    </button>
                  ))}
                </div>
              ) : null}
            </EmailModalInput>
            <CSVFileInput setFieldValue={setFieldValue} values={values} />
            <button
              onClick={downloadCSVSampleHandler}
              type="button"
              className="download-xls-sample-btn"
            >
              <img src="/assets/icons/icon_xlsFile.svg" alt="Download" />
              <span>Sample File</span>
              <Icon type="download" />
            </button>
          </div>
          {fieldError && <span className="control-error">{fieldError}</span>}
        </div>
      ) : (
        <div className="control-group">
          <label className={`control ${fieldError ? 'control-invalid' : ''}`}>
            <span className="required-field">
              To <span>*</span>
            </span>
            <div className="email-group-input">
              <input
                onBlur={onBlurEmailInput}
                type="text"
                placeholder="Enter email address or upload CSV file"
                onKeyDown={addEmailHandler}
              />
              <CSVFileInput setFieldValue={setFieldValue} values={values} />
            </div>
            {fieldError && <span className="control-error">{fieldError}</span>}
          </label>
        </div>
      )}
      {values.members?.length > 0 || values.groups?.length > 0 ? (
        <div className="selected-items-list group-emails-list">
          {(values.groups ?? []).map(({ name, id }, index) => (
            <span className="selected-item-chip" key={id}>
              <Icon type="group" />
              <span className="name">{name}</span>
              <FontAwesomeIcon
                onClick={() => removeEmailOrGroupHandler(index, 'groups')}
                icon={faRemove}
              />
            </span>
          ))}
          {values.members.map(({ email }, index) => (
            <span className="selected-item-chip" key={email}>
              <Icon type="mail" />
              <span className="name">{email}</span>
              <FontAwesomeIcon
                onClick={() => removeEmailOrGroupHandler(index, 'members')}
                icon={faRemove}
              />
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default ToEmailInput;
