import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

import { Loader } from 'components';
import { getUserAndInitialiseData } from 'redux/authentication/authenticationActions';
import { setAppLoading } from 'redux/authentication/authenticationSlice';
import { AppRoutes } from 'routes';
import { getUser } from 'utils/helpers';

import './i18n';

import 'react-toastify/dist/ReactToastify.css';
import 'scss/App.scss';
import 'scss/index.scss';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

function App() {
  const dispatch = useDispatch();
  const userFromStorage = getUser();

  useEffect(() => {
    if (!_.isEmpty(userFromStorage)) {
      dispatch(getUserAndInitialiseData());
    } else {
      dispatch(setAppLoading(false));
    }
  }, [userFromStorage]);

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <AppRoutes />
      </Suspense>
      <ToastContainer autoClose={6000} />
    </div>
  );
}

export default App;
