import { copyContentStateWithPrefix } from 'utils/helpers/richTextEditorHelpers';
import {
  formatSurveyQuestion,
  formatSurveySection,
} from 'utils/helpers/surveyHelpers';

import { NPS, RATING } from './questionTypes';

const npsSectionId = 'section-id-nps';
export const NPS_QUESTION_ID = 'nps-question';
const sectionId = 'section-id-initial';
export const INITIAL_QUESTION_ID = 'initial-question';
export const NPS_QUESTION_LABEL = 'Please select your satisfaction level?';
export const CUSTOMER_DETAIL_FORM_TITLE =
  'Thank you for providing your feedback. Would you like to share your contact information so we can reach out to you for your feedback?';

export const INITIAL_SURVEY = {
  title: '',
  sections: [
    formatSurveySection(npsSectionId, [
      formatSurveyQuestion({
        id: NPS_QUESTION_ID,
        label: copyContentStateWithPrefix(NPS_QUESTION_LABEL),
        question_type: NPS,
      }),
    ]),
    formatSurveySection(sectionId, [
      formatSurveyQuestion({
        id: INITIAL_QUESTION_ID,
        label: copyContentStateWithPrefix(''),
        question_type: RATING,
      }),
    ]),
  ],
  is_template: false,
  is_collecting_customer_info: true,
  is_collecting_nps_response: true,
  is_full_form_enabled: false,
};
